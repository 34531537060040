// title.js
import { reactive, watchEffect } from 'vue';

const state = reactive({
  title: 'Default Title',
});

export function useTitle() {
  watchEffect(() => {
    document.title = state.title;
  });

  return state;
}