<template>
  <div>
    <div class="canvas" ref="canvas"></div>
  </div>
</template>

<script>
import RotatingCubeConstructor from './rotatingCubeConstructor.js';
import { watch } from 'vue';

export default {
  data() {
    return {
      isRotating: false,
      switchCube: 0,
    };
  },
  name: 'RotatingCubeThree',
  mounted() {
    const canvas = this.$refs.canvas;

    // Initialize RotatingCubeConstructor with canvas and callback functions
    this.rotatingCube = new RotatingCubeConstructor(
      canvas,
      this.startAboutContent,    // No need to bind if context is not lost
      this.emitAboutEndMobile
    );

    // Watching Vuex store getters for scroll actions and page leave events
    watch(() => this.$store.getters.isAboutPageScrollDown, (newValue) => {
      if (newValue) {
        this.rotatingCube.tweenAboutScrollDown();
        this.$store.commit('setAboutPageScrollDown', false);
      }
    });

    watch(() => this.$store.getters.isAboutPageScrollUp, (newValue) => {
      if (newValue) {
        this.rotatingCube.tweenAboutScrollUp();
        this.$store.commit('setAboutPageScrollUp', false);
      }
    });

    watch(() => this.$store.getters.isAboutPageLeave, (newValue) => {
      if (newValue) {
        this.rotatingCube.tweenAboutLeave();
        this.$store.commit('setAboutPageLeave', false);
      }
    });
  },
  methods: {
    // Trigger the action when about page content starts
    startAboutContent() {
      this.$store.dispatch('setEnterAboutPage', true);
    },
    // Emit an event when the about animation ends on mobile
    emitAboutEndMobile() {
      this.$emit('about-end-mobile');
    }
  }
};
</script>

<style scoped lang="scss">
.canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s ease 0.2s;
  z-index: -1 !important;
}
</style>
