<template>
    <div class="slide-container" ref="slideContainer">
        <div class="slide-container-inner" ref="slideContainerInner">

            <div class="slide slide-1" ref="slide1">
                <div>
                    <SlidesText ref="aboutSlides1" :headerText="useMobileHeader(0)" :subHeaderText="useMobileSubheader(0)"
                        :mainText="slidesTextData[0].mainText" />
                </div>
            </div>

            <div class="slide slide-2" ref="slide2">
                <div>
                    <SlidesText ref="aboutSlides2" :headerText="useMobileHeader(1)" :subHeaderText="useMobileSubheader(1)"
                        :mainText="slidesTextData[1].mainText" />
                </div>
            </div>

            <div class="slide slide-3" ref="slide3">
                <div>
                    <SlidesText ref="aboutSlides3" :headerText="useMobileHeader(2)" :subHeaderText="useMobileSubheader(2)"
                        :mainText="slidesTextData[2].mainText" />
                </div>
            </div>

            <div class="slide slide-4" ref="slide4">
                <div>
                    <SlidesText ref="aboutSlides4" :headerText="useMobileHeader(3)" :subHeaderText="useMobileSubheader(3)"
                        :mainText="slidesTextData[3].mainText" />
                </div>
            </div>

        </div>
    </div>

    <div class="scroll-trigger" ref="scrollTrigger" @scroll="scrollTrigger">
        <div class="scroll-trigger-inner">
        </div>
    </div>

    <div class="slide-counter">
        {{ counter + 1 }} | 4
    </div>
    <LoadingSpinner v-if="isLoadingSpinner" />
    <RotatingCubeThree v-if="isRotatingCubeThree" />
</template>
  
<script>
import { onMounted } from 'vue';
import { useTitle } from '@/scripts/title';
import { watch } from 'vue';
import SlidesText from '@/components/templates/SlidesText/SlidesText.vue';
import RotatingCubeThree from '@/components/templates/RotatingCubeThree/RotatingCubeThree.vue';
import LoadingSpinner from '@/components/templates/LoadingSpinner/LoadingSpinner.vue';

export default {
    data() {
        return {
            counter: 0,
            isLoadingSpinner: true,
            prevScrollPos: 0,
            isIncremented: false,
            isRotatingCubeThree: false,
            slidesTextData: [
                {
                    headerText: "UI/UX Designer",
                    headerMobile: "UI/UX <br> Designer",
                    subHeaderText: "With a Twist of 3D Artistry",
                    subHeaderMobile: "With a Twist <br> of 3D Artistry",
                    mainText: "As a Digital Designer, my strengths lie in the areas of UI/UX Design and 3D Design. My unique combination of these fields enables me to create engaging user interfaces and immersive 3D worlds.",
                },
                {
                    headerText: "Guiding Principles",
                    headerMobile: "Guiding <br> Principles",
                    subHeaderText: "Open-Minded & Attuned",
                    subHeaderMobile: "Open-Minded & <br> Attuned",
                    mainText: "I am also skilled in Motion Design, Animation, and front-end development. This enables me to realize projects independently. Moreover, I am eager to contribute my expertise to your team collaboratively as a freelancer.",
                },
                {
                    headerText: "Work Trajectory",
                    headerMobile: "Work <br> Trajectory",
                    subHeaderText: "A Decade of Expertise",
                    subHeaderMobile: "A Decade <br> of Expertise",
                    mainText: "Matthias Heckel founded his office in 2021 and has since collaborated with renowned institutions like ZKM Karlsruhe and the Centre Pompidou. Prior to establishing his independent practice, he worked with prominent companies such as Daimler, MIWE, GRENKE, and KSB.",
                },
                {
                    headerText: "Connect today",
                    headerMobile: "Connect <br> Today",
                    subHeaderText: "Realizing Digital Visions",
                    subHeaderMobile: "Realizing Your <br> Digital Vision",
                    mainText: "With expertise in Figma, HTML, CSS, JS, VueJS, ThreeJS, CannonJs, and Blender, I am ready to bring your digital ideas to life. Reach out via email or phone – your vision awaits!",
                },
            ],
        };
    },
    name: 'AboutPage',
    beforeRouteLeave(to, from, next) {
        this.$store.dispatch('setTextContentSlide', true);
        this.$store.dispatch('setAboutPageLeave', true);
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, 500);
        }).then(() => {
            next();
        });
    },
    components: {
        SlidesText,
        RotatingCubeThree,
        LoadingSpinner
    },
    mounted() {
        setTimeout(() => {
            this.isRotatingCubeThree = true;
        }, 100);

        document.dispatchEvent(new Event('render-event'));

        watch(() => this.$store.getters.isEnterAboutPage, (newValue) => {
            this.isEnterAboutPage = newValue;
            if (newValue) {
                this.isLoadingSpinner = false;
                setTimeout(() => {
                    this.$refs.aboutSlides1.startAnimation();
                }, 1000);
                this.$store.commit('setEnterAboutPage', false);
            }
        });
    },

    setup() {
        const titleState = useTitle();
        onMounted(() => {
            titleState.title = 'Matthias Heckel - About';
        });
        return {};
    },
    methods: {
        useMobileSubheader(index) {
            const subHeaderText = this.slidesTextData[index].subHeaderText;
            const subHeaderMobile = this.slidesTextData[index].subHeaderMobile;

            if (window.innerWidth < 992) {
                return subHeaderMobile || subHeaderText;
            } else {
                return subHeaderText;
            }
        },
        useMobileHeader(index) {
            const headerText = this.slidesTextData[index].headerText;
            const headerMobile = this.slidesTextData[index].headerMobile;

            if (window.innerWidth < 992) {
                return headerMobile || headerText;
            } else {
                return headerText;
            }
        },
        scrollTrigger() {
            const scrollTrigger = this.$refs.scrollTrigger;
            const slideContainerInner = this.$refs.slideContainerInner;
            const currentScrollPos = scrollTrigger.scrollTop;

            const slide1 = this.$refs.slide1;
            const slide2 = this.$refs.slide2;
            const slide3 = this.$refs.slide3;

            scrollTrigger.scrollTop = '5';

            // Determine the scroll direction
            const scrollDirection = currentScrollPos > this.prevScrollPos ? 'down' : 'up';

            if (!this.isIncremented) {
                this.isIncremented = true;

                if (scrollDirection === 'down') {
                    if (this.counter < 3) {

                        this.counter++;

                        slideContainerInner.style.top = this.counter * -100 + 'vh';

                        if (this.counter === 1) {
                            this.$refs.aboutSlides2.startAnimation();
                            slide1.style.opacity = "0";
                            this.$store.dispatch('setAboutPageScrollDown', true);
                        }
                        if (this.counter === 2) {
                            this.$refs.aboutSlides3.startAnimation();
                            slide2.style.opacity = "0";
                            this.$store.dispatch('setAboutPageScrollDown', true);
                        }
                        if (this.counter === 3) {
                            this.$refs.aboutSlides4.startAnimation();
                            slide3.style.opacity = "0";
                            this.$store.dispatch('setAboutPageScrollDown', true);
                        }


                    }
                } else {
                    if (this.counter > 0) {

                        this.counter--;

                        if (this.counter === 0) {
                            this.$store.dispatch('setAboutPageScrollUp', true);
                            slide1.style.opacity = "1";
                        }
                        if (this.counter === 1) {
                            this.$store.dispatch('setAboutPageScrollUp', true);
                            slide2.style.opacity = "1";
                        }
                        if (this.counter === 2) {
                            this.$store.dispatch('setAboutPageScrollUp', true);
                            slide3.style.opacity = "1";
                        }

                        slideContainerInner.style.top = this.counter * -100 + 'vh';
                    }
                }
                setTimeout(() => {
                    this.isIncremented = false;
                }, 500);
            }

            this.prevScrollPos = currentScrollPos;
        }
    }
}

</script>
  
<style scoped lang="scss">
@import './style.scss';
</style>