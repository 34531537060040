<template>
  <div class="images-container" ref="imagesContainer">
    <div v-for="slide in slides" :key="slide.id" :class="'slide-' + slide.id">

      <div class="images-container-inner">
        <img :src="slide.image" alt="Slide Image" :id="'slide-img-' + slide.id">
      </div>

    </div>
  </div>

  <div class="text-container" ref="textContainer">

    <div class="projects-grid-container">
      <h1 class="projects-title" v-html="projectTitle"></h1>
      <p class="projects-description" v-html="projectDescription"></p>
      <div class="projects-role-and-credit">
        <div class="projects-role-credit-container">
          <h2 class="projects-role-credit-title">MY ROLE</h2>
          <div class="projects-role-credit-text">
            <ul>
              <li v-for="(item, index) in roleCreditList" :key="index" v-html="item"></li>
            </ul>
          </div>
        </div>
        <div class="projects-role-credit-container">
          <h2 class="projects-role-credit-title">CREDITS</h2>
          <div class="projects-role-credit-text">
            <ul>
              <li v-for="(item, index) in creditsList" :key="index" v-html="item"></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="CTA-container">
        <div class="text">SCROLL TO DISCOVER</div>
        <div class="svg">
          <svg width="69" height="13" viewBox="0 0 69 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 6.81934H67.7695" stroke="white" stroke-linecap="round" />
            <path d="M62.8828 1.42578L68.2765 6.81949L62.8828 12.2132" stroke="white" stroke-linecap="round" />
          </svg>
        </div>
        <div class="projectCTA" v-if="projectCTAHref">
          <a :href="projectCTAHref" target="_blank">
            <button v-html="projectCTAText"></button>
          </a>
        </div>
      </div>

    </div>
  </div>

  <div class="project-name-container" ref="projectName">
    <div class="project-name" v-html="projectName"></div>
    <router-link :to="{ path: String(nextProjectLinkDesktop) }" class="router-link">
      <button class="next" ref="next" v-html="nextProject"></button>
    </router-link>
    <div class="project-slide-number">{{ -(counter) + 1 }} / {{ slides.length }} </div>
  </div>

  <div class="slide-container" ref="slideContainer" @scroll="moveSlide">
    <div class="slide-inner">
    </div>
  </div>
</template>
    
<script>

import { onMounted } from 'vue';
import { useTitle } from '@/scripts/title';

export default {
  name: 'ProjectSingleView',
  props: {
    total: {
      type: String,
      default: '6',
    },
    slides: {
      type: Array,
      required: true,
    },
    projectTitle: {
      type: String,
      default: 'Default Project Title',
    },
    projectDescription: {
      type: String,
      default: 'Default Project Description',
    },
    projectName: {
      type: String,
      default: 'Project Name',
    },
    roleCreditList: {
      type: Array,
      default: () => [
        '<li>item</li>',
        '<li>item</li>',
        '<li>item</li>',
        '<li>item</li>',
      ],
    },
    creditsList: {
      type: Array,
      default: () => [
        '<li>item</li>',
        '<li>item</li>',
        '<li>item</li>',
        '<li>item</li>',
      ],
    },
    nextProject: {
      type: String,
      required: true,
    },
    projectCTAHref: {
      type: String,
      default: 'https://iconoclash.beyondmatter.eu/',
    },
    projectCTAText: {
      type: String,
      default: 'VIEW PROJECT ONLINE',
    },
    nextProjectLinkDesktop: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      counter: 1,
      isIncremented: false,
      textSwitch: false,
      scrollDirectionVertical: -1,
    };
  },
  setup() {
    const titleState = useTitle();

    onMounted(() => {
      titleState.title = 'Matthias Heckel - Projects | Iconoclash';
    });

    return {};
  },
  mounted() {
    
  },
  methods: {
    moveSlide() {
      const slideContainer = this.$refs.slideContainer;
      const currentScrollTop = slideContainer.scrollTop;
      // Determine the scroll direction for vertical scrolling
      this.scrollDirectionVertical = currentScrollTop === this.scrollPositionTop ? 0 : currentScrollTop < this.scrollPositionTop ? 1 : -1;

      slideContainer.scrollTop = '5';

      if (!this.isIncremented) {
        this.isIncremented = true;
        const imagesContainer = this.$refs.imagesContainer;
        const textContainer = this.$refs.textContainer;
        const projectName = this.$refs.projectName;
        const next = this.$refs.next;
        const slideWidth = window.innerWidth;

        // Ensure the counter stays within the valid range
        this.counter = Math.min(Math.max(-(this.$refs.imagesContainer.children.length - 1), this.counter + this.scrollDirectionVertical), 1);

        if (this.counter <= 0) {
          const slideWidth = window.innerWidth; // Get the viewport width
          imagesContainer.style.left = `${this.counter * slideWidth}px`;
          projectName.style.opacity = '1';
        }

        if (this.scrollDirectionVertical === -1) {
          textContainer.style.left = `${-slideWidth}px`;
        }

        if (this.scrollDirectionVertical === 1 && this.counter === 1) {
          textContainer.style.left = `${0}px`;
          projectName.style.opacity = '0';
        }

        if (this.counter == - (this.slides.length - 1)) {
          next.style.opacity = '1';
        } else {
          next.style.opacity = '0';
        }

        setTimeout(() => {
          this.isIncremented = false;
        }, 500);
      }

      // Update the scroll position for the next iteration
      this.scrollPositionTop = currentScrollTop;
    },
  },
}
</script>
    
<style scoped lang="scss">
@import './style.scss';
@import './text.scss';
</style>