
export function lights(scene, THREE) {
  // Ambient Light
  const ambientLight = new THREE.AmbientLight(0xffffff);
  scene.add(ambientLight);

  // Directional Light
  const directionalLight = new THREE.DirectionalLight(0xffffff, 5);
  const distance = 20;
  directionalLight.position.set(-distance, distance, distance);

  // Enable shadows for the directional light
  directionalLight.castShadow = true;
  directionalLight.shadow.mapSize.width = 2048;
  directionalLight.shadow.mapSize.height = 2048;
  directionalLight.shadow.camera.left = -distance;
  directionalLight.shadow.camera.right = distance;
  directionalLight.shadow.camera.top = distance;
  directionalLight.shadow.camera.bottom = -distance;
  directionalLight.shadow.camera.far = 3 * distance;
  directionalLight.shadow.camera.near = distance;

  // Add the directional light to the scene
  scene.add(directionalLight);
}