<template>
  <div class="legal-container" ref="legalContainer">

    <div class="legal-container-text" ref="legalContainerText">
      <div class="close-icon-legal">
        <button>
          <router-link to="/contact">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256">
              <path
                d="M205.66,194.34a8,8,0,0,1-11.32,11.32L128,139.31,61.66,205.66a8,8,0,0,1-11.32-11.32L116.69,128,50.34,61.66A8,8,0,0,1,61.66,50.34L128,116.69l66.34-66.35a8,8,0,0,1,11.32,11.32L139.31,128Z">
              </path>
            </svg>
          </router-link>
        </button>
      </div>
      <h1>Imprint</h1>
      <p>Matthias Heckel <br>Hegaustr. 2a,<br>76199 Karlsruhe<br> <br>mail@matthiasheckel.com
        <br><br>Concept, Design and development: Matthias Heckel<br><br>
        Privacy Policy:
        <button class="english-pp" @click="callEnglish">English</button>
        <button class="german-pp" @click="callGerman">German</button>
      </p>
      <div class="german-version" ref="germanVersion">
        <PrivacyPolicyGerman v-if="isPrivacyPolicyGerman" />
      </div>
      <div class="english-version" ref="englishVersion">
        <PrivacyPolicyEnglish v-if="isPrivacyPolicyEnglish" />
      </div>
    </div>
    <div class="legal-spacer"><br><br><br><br><br><br></div>

  </div>
</template>
  
<script>
import { onMounted } from 'vue';
import { useTitle } from '@/scripts/title';
import PrivacyPolicyEnglish from '@/components/templates/PrivacyPolicy/PrivacyPolicyEnglish.vue';
import PrivacyPolicyGerman from '@/components/templates/PrivacyPolicy/PrivacyPolicyGerman.vue';

export default {
  data() {
    return {
      isPrivacyPolicyGerman: true,
      isPrivacyPolicyEnglish: true,
    };
  },
  name: 'LegalPage',
  components: {
    PrivacyPolicyEnglish,
    PrivacyPolicyGerman,
  },
  beforeRouteLeave(to, from, next) {
    this.closeLegal();
    return new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, 500);
    }).then(() => {
      next();
    });
  },
  mounted() {
    document.dispatchEvent(new Event('render-event'));
    setTimeout(() => {
      this.callLegal();
    }, 300);
  },
  setup() {
    const titleState = useTitle();

    onMounted(() => {
      titleState.title = 'Matthias Heckel - Legal';
    });

    return {};
  },
  methods: {
    callLegal() {
      const legalContainer = this.$refs.legalContainer;
      legalContainer.style.left = '0px';
    },
    closeLegal() {
      const legalContainer = this.$refs.legalContainer;
      legalContainer.style.left = window.innerWidth + 'px';
    },
    callEnglish() {
      const germanVersion = this.$refs.germanVersion;
      const englishVersion = this.$refs.englishVersion;
      const legalContainerText = this.$refs.legalContainerText;

      germanVersion.style.display = 'none';
      englishVersion.style.display = 'block';
      if (window.innerWidth > 600) {
        legalContainerText.style.marginTop = '50px';
      } else {
        legalContainerText.style.marginTop = '10px';
      }
    },
    callGerman() {
      const germanVersion = this.$refs.germanVersion;
      const englishVersion = this.$refs.englishVersion;
      const legalContainerText = this.$refs.legalContainerText;

      germanVersion.style.display = 'block';
      englishVersion.style.display = 'none';
      if (window.innerWidth > 600) {
        legalContainerText.style.marginTop = '50px';
      } else {
        legalContainerText.style.marginTop = '10px';
      }
    }

  }
}
</script>
  
<style scoped lang="scss">
@import './style.scss';
</style>