// rotatingCubeConstructor.js
import * as THREE from 'three';
import TWEEN from '@tweenjs/tween.js';
import { createTweenAnimations } from './scripts/tweenAnimations.js';
import { checkIsMobileDevice } from '@/scripts/utils';

export default class RotatingCubeConstructor {
  constructor(canvasElement, startAboutContent, emitAboutEndMobile) {
    this.canvasElement = canvasElement;
    this.startAboutContent = startAboutContent;
    this.emitAboutEndMobile = emitAboutEndMobile;
    this.counter = 0;
    this.camera = null;
    this.scene = null;
    this.renderer = null;
    this.mesh = null;

    this.initThree();

    // Initialize tween animations
    const tweens = createTweenAnimations(THREE, TWEEN, this);
    this.tweenToAbout = tweens.tweenToAbout;
    this.tweenToAboutEnd = tweens.tweenToAboutEnd;
    this.tweenToAboutEndMobile = tweens.tweenToAboutEndMobile;
    this.tweenAboutScrollDown = tweens.tweenAboutScrollDown;
    this.tweenAboutScrollUp = tweens.tweenAboutScrollUp;
    this.tweenAboutLeave = tweens.tweenAboutLeave;
  }

  initThree() {
    this.scene = new THREE.Scene();
    this.camera = new THREE.PerspectiveCamera(60, window.innerWidth / window.innerHeight, 0.1, 1000);
    this.camera.position.set(0, 0, 3);

    this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.canvasElement.appendChild(this.renderer.domElement);

    const textureLoader = new THREE.TextureLoader();

    const cubeX = 1;
    const cubeY = 1;
    const cubeZ = 1;
    let scale = 2.4;

    switch (true) {
      case window.innerWidth < 600:
        scale = 1.5;
        break;
      case window.innerWidth > 600 && window.innerWidth < 1600:
        scale = 2;
    }

    const light = new THREE.AmbientLight(0xffffff, 5);
    this.scene.add(light);

    const texturePaths = [
      '/images/cube/uni_1.jpg',
      '/images/cube/uni_2.jpg',
      '/images/cube/uni_3_top.jpg',
      '/images/cube/uni_4_top.jpg',
      '/images/cube/uni_5.jpg',
      '/images/cube/uni_6.jpg',
    ];

    const materials = [];
    let texturesLoaded = 0;

    texturePaths.forEach((path) => {
      const texture = textureLoader.load(path, () => {
        texturesLoaded++;
        if (texturesLoaded === texturePaths.length) {
          this.tweenToAbout();
          this.startAboutContent();

          if (!checkIsMobileDevice()) {
            setTimeout(() => {
              this.tweenToAboutEnd();
            }, 500);
          } else {
            this.tweenToAboutEndMobile();
          }
        }
      });

      texture.colorSpace = THREE.SRGBColorSpace;
      texture.repeat.set(1, 1);
      const material = new THREE.MeshStandardMaterial({ map: texture });
      materials.push(material);
    });

    const geometry = new THREE.BoxGeometry(cubeX * scale * 1.335, cubeY * scale, cubeZ * 1.335 * scale);
    const cubeMaterials = [
      materials[0],
      materials[1],
      materials[2],
      materials[3],
      materials[4],
      materials[5],
    ];

    this.mesh = new THREE.Mesh(geometry, cubeMaterials);
    this.scene.add(this.mesh);
    this.mesh.scale.set(1, 1, 1);

    window.addEventListener('resize', this.onWindowResize.bind(this));
    this.animate();
  }

  animate() {
    requestAnimationFrame(this.animate.bind(this));
    TWEEN.update();
    this.renderer.render(this.scene, this.camera);
  }

  onWindowResize() {
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(window.innerWidth, window.innerHeight);
  }
}
