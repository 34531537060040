<template>
    <div class="spinner-container">
        <div class="spinner-container-inner">
            <div class="spinner"></div>
        </div>
    </div>
</template>
    
<script>
export default {
    name: 'LoadingSpinner',
    mounted() {
    },
    methods: {
    }
};
</script>
    
<style scoped lang="scss">
@import './style.scss';
</style>