<template>
    <div class="content-container" ref="contentContainer">
        <div class="content-container-inner">
            <div class="text-container">
                <h1>MATTHIAS HECKEL</h1>
                <h2>UI/UX Design, <br>3D Design, <br>Physics & Motion </h2>
                <h3>I am a freelance designer based in Karlsruhe, Germany. Eager to collaborate with your team or take on projects independently.</h3>
                <div class="more-information-action">
                    <router-link to="/about" class="custom-router-link">
                        <button class="read-more">READ MORE</button>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
    <PhysicsCube v-if="isPhysicsCube" />
</template>
  
<script>
import { onMounted } from 'vue';
import { useTitle } from '@/scripts/title';
import PhysicsCube from '@/components/templates/PhysicsCube/PhysicsCube.vue';

export default {
    data() {
        return {
            isPhysicsCube: true,
        };
    },
    name: 'HomePage',
    beforeRouteLeave(to, from, next) {
        this.closeContent();
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, 500);
        }).then(() => {
            next();
        });
    },
    components: {
        PhysicsCube,
    },
    mounted() {
        document.dispatchEvent(new Event('render-event'));
        setTimeout(() => {
            this.callContent();
        }, 500);
    },
    setup() {
        const titleState = useTitle();
        onMounted(() => {
            titleState.title = 'Matthias Heckel';
        });
        return {};
    },
    methods: {
        callContent() {
            const contentContainer = this.$refs.contentContainer;
            contentContainer.style.opacity = '1';
        },
        closeContent() {
            const contentContainer = this.$refs.contentContainer;
            contentContainer.style.opacity = '0';
        }
    }
}

</script>
  
<style scoped lang="scss">
@import './style.scss';
</style>