import * as THREE from 'three';
import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
import { ImprovedNoise } from 'three/addons/math/ImprovedNoise.js';
import { Lensflare, LensflareElement } from 'three/addons/objects/Lensflare.js';
import { vertexShader, fragmentShader } from './shaders.js';
import { createStars } from './stars.js';
import { GUI } from 'three/addons/libs/lil-gui.module.min.js';
import TWEEN from 'tween.js';

export default class RotatingCloud {
  constructor(container) {
    this.container = container;

    // Three.js variables
    this.renderer = null;
    this.scene = null;
    this.camera = null;
    this.mesh = null;
    this.starsGroup = null;
    this.lensflare = null;
    this.textureFlare0 = null;
    this.textureFlare3 = null;
    this.controls = null;
    this.lensLight = null;

    this.animationActive = true;
    this.lastTimestamp = performance.now();
    this.frameCount = 0;
    this.elapsedMilliseconds = 0;
    this.fps = 0;
    this.performanceCheck = false;

    this.init();
  }

  init() {
    this.initThree();
    this.animate();
    window.addEventListener('resize', this.onWindowResize.bind(this));
  }

  initThree() {
    // Initialize the renderer
    this.renderer = new THREE.WebGLRenderer({ alpha: true });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.renderer.shadowMap.enabled = true;
    this.container.appendChild(this.renderer.domElement);

    // Initialize the scene and camera
    this.scene = new THREE.Scene();
    this.camera = new THREE.PerspectiveCamera(60, window.innerWidth / window.innerHeight, 0.5, 100);
    this.camera.position.set(0, 0, 6);

    // Add ambient and directional lights
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.1);
    this.scene.add(ambientLight);

    const dirLight = new THREE.DirectionalLight(0xffffff, 0.15);
    dirLight.position.set(0, -1, 0).normalize();
    this.scene.add(dirLight);

    // Lensflare setup
    const textureLoader = new THREE.TextureLoader();
    this.textureFlare0 = textureLoader.load('images/lensflare/lens_1.png');
    this.textureFlare3 = textureLoader.load('images/lensflare/lens_2.png');

    const light = new THREE.PointLight(0xffffff, 1, 100);
    light.position.set(0, 0, 0);
    this.scene.add(light);

    this.addLight(0.55, 0.9, 0.5, 5000, 0, -1000, 30, 100);

    // Orbit controls
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.enableZoom = false;
    this.controls.enabled = false;
    setTimeout(() => {
      this.controls.enabled = true;
    }, 2000);

    // Create stars and mesh
    this.starsGroup = createStars(this.scene);
    this.createMesh();

    // Add GUI controls for debugging
    this.addGUI();

    // Animate camera look at
    this.animateCameraLookAt(new THREE.Vector3(0, 0, 1.5));
  }

  createMesh() {
    const size = 128;
    const data = new Uint8Array(size * size * size);
    const perlin = new ImprovedNoise();
    const scale = 0.05;
    const vector = new THREE.Vector3();
    let i = 0;

    for (let z = 0; z < size; z++) {
      for (let y = 0; y < size; y++) {
        for (let x = 0; x < size; x++) {
          const d = 1.0 - vector.set(x, y, z).subScalar(size / 2).divideScalar(size).length();
          data[i] = (128 + 128 * perlin.noise(x * scale / 1.5, y * scale, z * scale / 1.5)) * d * d;
          i++;
        }
      }
    }

    const texture = new THREE.Data3DTexture(data, size, size, size);
    texture.format = THREE.RedFormat;
    texture.minFilter = THREE.LinearFilter;
    texture.magFilter = THREE.LinearFilter;
    texture.unpackAlignment = 1;
    texture.needsUpdate = true;

    const geometry = new THREE.BoxGeometry(1, 1, 1);
    const material = new THREE.RawShaderMaterial({
      glslVersion: THREE.GLSL3,
      uniforms: {
        base: { value: new THREE.Color(0x286FB1) },
        map: { value: texture },
        cameraPos: { value: new THREE.Vector3() },
        threshold: { value: 0.25 },
        opacity: { value: 0.25 },
        range: { value: 0.1 },
        steps: { value: 100 },
        frame: { value: 0 },
      },
      vertexShader,
      fragmentShader,
      side: THREE.BackSide,
      transparent: true,
    });

    this.mesh = new THREE.Mesh(geometry, material);
    this.scene.add(this.mesh);
  }

  addLight(h, s, l, x, y, z, x1, x2) {
    this.lensLight = new THREE.PointLight(0xffffff, 1.5, 10, 0);
    this.lensLight.color.setHSL(h, s, l);
    this.lensLight.position.set(x, y, z);
    this.scene.add(this.lensLight);

    this.lensflare = new Lensflare();
    this.lensflare.addElement(new LensflareElement(this.textureFlare0, x1, 0, this.lensLight.color));
    this.lensflare.addElement(new LensflareElement(this.textureFlare3, x2, 0.6));
    this.lensflare.addElement(new LensflareElement(this.textureFlare3, 400, 2));

    this.lensLight.add(this.lensflare);
  }

  animateCameraLookAt(targetPosition, duration = 1000) {
    new TWEEN.Tween(this.camera.position)
      .to(targetPosition, duration)
      .easing(TWEEN.Easing.Exponential.Out)
      .start();
  }

  onWindowResize() {
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(window.innerWidth, window.innerHeight);
  }

  addGUI() {
    const parameters = {
      threshold: 0.25,
      opacity: 0.25,
      range: 0.1,
      steps: 100,
    };

    const material = this.mesh.material;
    const updateMaterial = () => {
      material.uniforms.threshold.value = parameters.threshold;
      material.uniforms.opacity.value = parameters.opacity;
      material.uniforms.range.value = parameters.range;
      material.uniforms.steps.value = parameters.steps;
    };

    const gui = new GUI();
    gui.add(parameters, 'threshold', 0, 1, 0.01).onChange(updateMaterial);
    gui.add(parameters, 'opacity', 0, 1, 0.01).onChange(updateMaterial);
    gui.add(parameters, 'range', 0, 1, 0.01).onChange(updateMaterial);
    gui.add(parameters, 'steps', 0, 200, 1).onChange(updateMaterial);
    gui.domElement.style.visibility = 'hidden';
  }

  animate() {
    const animateFrame = (timestamp) => {
      this.elapsedMilliseconds = timestamp - this.lastTimestamp;
      this.frameCount++;

      if (this.elapsedMilliseconds >= 1000) {
        this.fps = this.frameCount / (this.elapsedMilliseconds / 1000);
        this.frameCount = 0;
        this.lastTimestamp = timestamp;
      }

      if (this.performanceCheck && this.fps < 15) {
        this.mesh.visible = false;
        this.performanceCheck = false;
      }

      if (this.animationActive) {
        requestAnimationFrame(animateFrame);
      }

      this.mesh.material.uniforms.cameraPos.value.copy(this.camera.position);
      this.mesh.rotation.y = -performance.now() / 7500;
      this.mesh.material.uniforms.frame.value++;

      this.starsGroup.rotation.y += 0.001;

      TWEEN.update();
      this.renderer.render(this.scene, this.camera);
    };

    animateFrame();
  }

  stopAnimation() {
    this.animationActive = false;
  }
}
