import { createApp } from 'vue';
import VueMatomo from 'vue-matomo'
import App from './App.vue';
import { createRouter, createWebHistory } from 'vue-router';
import { useTitle } from './scripts/title';
import store from './store';
import NavBar from './components/templates/NavBar/NavBar.vue';
import { checkIsMobileDevice } from './scripts/utils';

const app = createApp(App);

app.use(VueMatomo, {
  // Configure your matomo server and site by providing
  host: '//matomo.matthiasheckel.com/',
  siteId: 1,
})

import HomePage from './components/views/home/HomePage.vue';
import AboutPage from './components/views/about/AboutPage.vue';
import ProjectsPage from './components/views/projects/ProjectsPage.vue';
import ContactPage from './components/views/contact/ContactPage.vue';
import LegalPage from './components/views/legal/LegalPage.vue';

import IconoclashPage from './components/views/projects/iconoclash/IconoclashPage.vue';
import LesImmateriauxPage from './components/views/projects/les_immateriaux/LesImmateriauxPage.vue';
import TradeMachinesPage from './components/views/projects/trademachines/TradeMachinesPage.vue';
import GenericExhibitionPlatformPage from './components/views/projects/generic_exhibition_platform/GenericExhibitionPlatformPage.vue';

const routes = [
  { path: '/', component: HomePage },
  { path: '/about', name: 'about', component: AboutPage },
  { path: '/projects', name: 'projects', component: ProjectsPage },
  { path: '/contact', name: 'contact', component: ContactPage },
  { path: '/contact/legal', name: 'legal', component: LegalPage },
  // projects
  { path: '/projects/iconoclash', component: IconoclashPage },
  { path: '/projects/lesimmateriaux', component: LesImmateriauxPage },
  { path: '/projects/trademachines', component: TradeMachinesPage },
  { path: '/projects/genericexhibitionplatform', component: GenericExhibitionPlatformPage },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

app.use(router);
app.use(useTitle);
app.use(store); 

// Make the function globally available
app.config.globalProperties.$checkIsMobileDevice = checkIsMobileDevice;

// Register the component globally
app.component('NavBar', NavBar);

app.mount('#app');

window._paq.push(['trackPageView']); //To track pageview