import { createStore } from 'vuex';

const store = createStore({
  state: {
    // Enter about page
    enterAboutPage: false,
    //Text Slides
    textContentSlide: false,
    projectsContentSlide: false,
    // Others
    aboutPageScrollDown: false,
    aboutPageScrollUp: false,
    aboutPageLeave: false,
    projectsPageHover: false,
    contactPageLeave: false, 
  },
  mutations: {
    // Enter About Page
    setEnterAboutPage(state, value) {
      state.enterAboutPage = value;
    },
    //Text Slides
    setTextContentSlide(state, value) {
      state.textContentSlide = value;
    },
    setProjectsContentSlide(state, value) {
      state.projectsContentSlide = value;
    },
    // Others
    setAboutPageScrollDown(state, value) {
      state.aboutPageScrollDown = value;
    },
    setAboutPageScrollUp(state, value) {
      state.aboutPageScrollUp = value;
    },
    setAboutPageLeave(state, value) {
      state.aboutPageLeave = value;
    },
    setProjectsPageHover(state, value) {
      state.projectsPageHover = value;
    },
    setContactPageLeave(state, value) {
      state.contactPageLeave = value;
    },
  },
  actions: {
    // Enter About Page
    setEnterAboutPage({ commit }) {
      commit('setEnterAboutPage', true);
    },
    // Text Slides
    setTextContentSlide({ commit }) {
      commit('setTextContentSlide', true);
    },
    setProjectsContentSlide({ commit }) {
      commit('setProjectsContentSlide', true);
    },
    // Others
    setAboutPageScrollDown({ commit }) {
      commit('setAboutPageScrollDown', true);
    },
    setAboutPageScrollUp({ commit }) {
      commit('setAboutPageScrollUp', true);
    },
    setAboutPageLeave({ commit }) {
      commit('setAboutPageLeave', true);
    },
    setProjectsPageHover({ commit }) {
      commit('setProjectsPageHover', true);
    },
    setContactPageLeave({ commit }) {
      commit('setContactPageLeave', true);
    }
  },
  getters: {
    // Enter About Page
    isEnterAboutPage: (state) => state.enterAboutPage,
    //Text Slides
    isTextContentSlide: (state) => state.textContentSlide,
    isProjectsContentSlide: (state) => state.projectsContentSlide,
    // Cube rotation
    isAboutPageScrollDown: (state) => state.aboutPageScrollDown,
    isAboutPageScrollUp: (state) => state.aboutPageScrollUp,
    isAboutPageLeave: (state) => state.aboutPageLeave,
    isProjectsPageHover: (state) => state.projectsPageHover,
    isContactPageLeave: (state) => state.contactPageLeave
  },
});

export default store;
