<template>
    <div>
      <div class="canvas-rotating-cloud" ref="canvasRotatingCloud" @mousedown="onMouseDown" @mouseup="onMouseUp"
        @touchstart="onTouchStart" @touchend="onTouchEnd"></div>
    </div>
  </template>
  
  <script>
  import RotatingCloud from './rotatingCloudConstructor';
  import { watch } from 'vue';
  
  export default {
    name: 'RotatingCloud',
    mounted() {
      this.rotatingCloudInstance = new RotatingCloud(this.$refs.canvasRotatingCloud);
      
      watch(() => this.$store.getters.isContactPageLeave, (newValue) => {
        if (newValue) {
          this.tweenContactLeave();
          this.$store.commit('setContactPageLeave', false);
        }
      });
    },
    beforeUnmount() {
      this.rotatingCloudInstance.stopAnimation();
    },
    methods: {
      tweenContactLeave() {
        this.$refs.canvasRotatingCloud.style.opacity = 0;
      },
      onMouseDown() {
        this.$emit('mousedown');
      },
      onMouseUp() {
        this.$emit('mouseup');
      },
      onTouchStart(event) {
        event.preventDefault();
        this.$emit('mousedown');
      },
      onTouchEnd(event) {
        event.preventDefault();
        this.$emit('mouseup');
      }
    }
  };
  </script>
  
  <style scoped lang="scss">
  .canvas-rotating-cloud {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    transition: opacity 0.3s linear 0.2s;
  }
  </style>
  