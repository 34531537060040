// utils.js
export const checkIsMobileDevice = () => {
    const isWindowNarrow = window.innerWidth < 600;
    const isphoneFlipped = window.innerHeight < 600;
  
    if (isWindowNarrow || isphoneFlipped) {
      return true;
    }
    return false;
  };

  export const checkIsTabletDevice = () => {
      const details = navigator.userAgent;
      const regexp = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
      return regexp.test(details);
  };

  export const checkIsRealMobileDevice = () => {
    const isWindowNarrow = window.innerWidth < 700;
    const isphoneFlipped = window.innerHeight < 600;
  
    if (isWindowNarrow || isphoneFlipped) {
      const details = navigator.userAgent;
      const regexp = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
      return regexp.test(details);
    }
    return false;
  };