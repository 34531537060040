<!-- App.vue -->
<template>
  <div>
    <NavBar />
    <BackgroundTemp v-if="isBackgroundTemp" />
    <SplashscreenPage v-if="isSplashScreenActive" />
    <PhysicsCube v-if="isPhysicsCube" />
    <LoadingSpinner v-if="isLoading" />
    <router-view />
  </div>
</template>

<script>
import BackgroundTemp from '@/components/templates/BackgroundTemp/BackgroundTemp.vue';
import SplashscreenPage from '@/components/templates/SplashscreenPage/SplashscreenPage.vue';
import PhysicsCube from '@/components/templates/PhysicsCube/PhysicsCube.vue';
import LoadingSpinner from '@/components/templates/LoadingSpinner/LoadingSpinner.vue';


export default {
  data() {
    return {
      isSplashScreenActive: true,
      isPhysicsCube: false,
      isBackgroundTemp: true,
      isLoading: false,
    };
  },
  name: 'App',
  components: {
    PhysicsCube,
    SplashscreenPage,
    BackgroundTemp,
    LoadingSpinner
  },
  mounted() {
     // Initialize prevWidth with the initial window width
  this.prevWidth = window.innerWidth;

// Add resize event listener
window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    // Remove the resize event listener to prevent memory leaks
    window.removeEventListener('resize', this.handleResize);
  },
  setup() {
  },
  methods: {
    handleResize() {
      const newWidth = window.innerWidth;

      if (this.prevWidth !== newWidth) {
        // Only reload if there's a change in width
        this.prevWidth = newWidth;
        location.reload(true);
      }
    },
  },
}
</script>

<style lang="scss">
@import './styles/main.scss';
</style>
