<template>
   <h1>Datenschutzerklärung</h1>
          <p>Personenbezogene Daten (nachfolgend zumeist nur „Daten“ genannt) werden von uns nur im
            Rahmen
            der
            Erforderlichkeit sowie zum Zwecke der Bereitstellung eines funktionsfähigen und
            nutzerfreundlichen Internetauftritts, inklusive seiner Inhalte und der dort angebotenen
            Leistungen, verarbeitet.</p>
          <p>Gemäß Art. 4 Ziffer 1. der Verordnung (EU) 2016/679, also der Datenschutz-Grundverordnung
            (nachfolgend nur „DSGVO“ genannt), gilt als „Verarbeitung“ jeder mit oder ohne Hilfe
            automatisierter Verfahren ausgeführter Vorgang oder jede solche Vorgangsreihe im
            Zusammenhang
            mit personenbezogenen Daten, wie das Erheben, das Erfassen, die Organisation, das
            Ordnen,
            die
            Speicherung, die Anpassung oder Veränderung, das Auslesen, das Abfragen, die Verwendung,
            die
            Offenlegung durch Übermittlung, Verbreitung oder eine andere Form der Bereitstellung,
            den
            Abgleich oder die Verknüpfung, die Einschränkung, das Löschen oder die Vernichtung.</p>
          <p>Mit der nachfolgenden Datenschutzerklärung informieren wir Sie insbesondere über Art,
            Umfang,
            Zweck, Dauer und Rechtsgrundlage der Verarbeitung personenbezogener Daten, soweit wir
            entweder
            allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung
            entscheiden.
            Zudem
            informieren wir Sie nachfolgend über die von uns zu Optimierungszwecken sowie zur
            Steigerung
            der
            Nutzungsqualität eingesetzten Fremdkomponenten, soweit hierdurch Dritte Daten in
            wiederum
            eigener Verantwortung verarbeiten.</p>
          <p>Unsere Datenschutzerklärung ist wie folgt gegliedert:</p>
          <p>I. Informationen über uns als Verantwortliche<br>II. Rechte der Nutzer und
            Betroffenen<br>III.
            Informationen zur Datenverarbeitung</p>
          <h2>I. Informationen über uns als Verantwortliche</h2>
          <p>Verantwortlicher Anbieter dieses Internetauftritts im datenschutzrechtlichen Sinne ist:
          </p>
          <p>Matthias Heckel<br>Hegaustr. 2a<br>76199 Karlsruhe<br><br>E-Mail: mail@matthiasheckel.com</p>
          <h2>II. Rechte der Nutzer und Betroffenen</h2>
          <p>Mit Blick auf die nachfolgend noch näher beschriebene Datenverarbeitung haben die Nutzer
            und
            Betroffenen das Recht</p>
          <ul>
            <li>auf Bestätigung, ob sie betreffende Daten verarbeitet werden, auf Auskunft über die
              verarbeiteten Daten, auf weitere Informationen über die Datenverarbeitung sowie auf
              Kopien
              der Daten (vgl. auch Art. 15 DSGVO);</li>
            <li>auf Berichtigung oder Vervollständigung unrichtiger bzw. unvollständiger Daten (vgl.
              auch
              Art. 16 DSGVO);</li>
            <li>auf unverzügliche Löschung der sie betreffenden Daten (vgl. auch Art. 17 DSGVO),
              oder,
              alternativ, soweit eine weitere Verarbeitung gemäß Art. 17 Abs. 3 DSGVO erforderlich
              ist,
              auf Einschränkung der Verarbeitung nach Maßgabe von Art. 18 DSGVO;</li>
            <li>auf Erhalt der sie betreffenden und von ihnen bereitgestellten Daten und auf
              Übermittlung
              dieser Daten an andere Anbieter/Verantwortliche (vgl. auch Art. 20 DSGVO);</li>
            <li>auf Beschwerde gegenüber der Aufsichtsbehörde, sofern sie der Ansicht sind, dass die
              sie
              betreffenden Daten durch den Anbieter unter Verstoß gegen datenschutzrechtliche
              Bestimmungen
              verarbeitet werden (vgl. auch Art. 77 DSGVO).</li>
          </ul>
          <p>Darüber hinaus ist der Anbieter dazu verpflichtet, alle Empfänger, denen gegenüber Daten
            durch
            den Anbieter offengelegt worden sind, über jedwede Berichtigung oder Löschung von Daten
            oder
            die
            Einschränkung der Verarbeitung, die aufgrund der Artikel 16, 17 Abs. 1, 18 DSGVO
            erfolgt, zu
            unterrichten. Diese Verpflichtung besteht jedoch nicht, soweit diese Mitteilung
            unmöglich
            oder
            mit einem unverhältnismäßigen Aufwand verbunden ist. Unbeschadet dessen hat der Nutzer
            ein
            Recht
            auf Auskunft über diese Empfänger.</p>
          <p><strong>Ebenfalls haben die Nutzer und Betroffenen nach Art. 21 DSGVO das Recht auf
              Widerspruch
              gegen die künftige Verarbeitung der sie betreffenden Daten, sofern die Daten durch
              den
              Anbieter nach Maßgabe von Art. 6 Abs. 1 lit. f) DSGVO verarbeitet werden.
              Insbesondere
              ist
              ein Widerspruch gegen die Datenverarbeitung zum Zwecke der Direktwerbung
              statthaft.</strong>
          </p>
          <h2>III. Informationen zur Datenverarbeitung</h2>
          <p>Ihre bei Nutzung unseres Internetauftritts verarbeiteten Daten werden gelöscht oder
            gesperrt,
            sobald der Zweck der Speicherung entfällt, der Löschung der Daten keine gesetzlichen
            Aufbewahrungspflichten entgegenstehen und nachfolgend keine anderslautenden Angaben zu
            einzelnen
            Verarbeitungsverfahren gemacht werden.</p>
          <h3 class="jet-listing-dynamic-field__content">Serverdaten</h3>
          <p>Aus technischen Gründen, insbesondere zur Gewährleistung eines sicheren und stabilen
            Internetauftritts, werden Daten durch Ihren Internet-Browser an uns bzw. an unseren
            Webspace-Provider übermittelt. Mit diesen sog. Server-Logfiles werden u.a. Typ und
            Version
            Ihres
            Internetbrowsers, das Betriebssystem, die Website, von der aus Sie auf unseren
            Internetauftritt
            gewechselt haben (Referrer URL), die Website(s) unseres Internetauftritts, die Sie
            besuchen,
            Datum und Uhrzeit des jeweiligen Zugriffs sowie die IP-Adresse des Internetanschlusses,
            von
            dem
            aus die Nutzung unseres Internetauftritts erfolgt, erhoben.</p>
          <p>Diese so erhobenen Daten werden vorrübergehend gespeichert, dies jedoch nicht gemeinsam
            mit
            anderen Daten von Ihnen.</p>
          <p>Diese Speicherung erfolgt auf der Rechtsgrundlage von Art. 6 Abs. 1 lit. f) DSGVO. Unser
            berechtigtes Interesse liegt in der Verbesserung, Stabilität, Funktionalität und
            Sicherheit
            unseres Internetauftritts.</p>
          <p>Die Daten werden spätestens nach sieben Tage wieder gelöscht, soweit keine weitere
            Aufbewahrung
            zu Beweiszwecken erforderlich ist. Andernfalls sind die Daten bis zur endgültigen
            Klärung
            eines
            Vorfalls ganz oder teilweise von der Löschung ausgenommen.</p>
          <h3 class="jet-listing-dynamic-field__content">Instagram</h3>
          <p>Zur Bewerbung unserer Produkte und Leistungen sowie zur Kommunikation mit Interessenten
            oder
            Kunden betreiben wir eine Firmenpräsenz auf der Plattform Instagram.</p>
          <p>Auf dieser Social-Media-Plattform sind wir gemeinsam mit der Meta Platforms Ireland
            Limited,
            4
            Grand Canal Square, Dublin 2, Irland, verantwortlich.</p>
          <p>Der Datenschutzbeauftragte von Instagram kann über ein Kontaktformular erreicht werden:
          </p>
          <p><a href="https://www.facebook.com/help/contact/540977946302970" target="_blank"
              rel="noopener nofollow">https://www.facebook.com/help/contact/540977946302970</a>
          </p>
          <p>Die gemeinsame Verantwortlichkeit haben wir in einer Vereinbarung bezüglich der
            jeweiligen
            Verpflichtungen im Sinne der DSGVO geregelt. Diese Vereinbarung, aus der sich die
            gegenseitigen
            Verpflichtungen ergeben, ist unter dem folgenden Link abrufbar:</p>
          <p><a href="https://www.facebook.com/legal/terms/page_controller_addendum" target="_blank"
              rel="noopener nofollow">https://www.facebook.com/legal/terms/page_controller_addendum</a>
          </p>
          <p>Rechtsgrundlage für die dadurch erfolgende und nachfolgend wiedergegebene Verarbeitung
            von
            personenbezogenen Daten ist Art. 6 Abs. 1 lit. f DSGVO. Unser berechtigtes Interesse
            besteht
            an
            der Analyse, der Kommunikation sowie dem Absatz und der Bewerbung unserer Produkte und
            Leistungen.</p>
          <p>Rechtsgrundlage kann auch eine Einwilligung des Nutzers gemäß Art. 6 Abs. 1 lit. a DSGVO
            gegenüber dem Plattformbetreiber sein. Die Einwilligung hierzu kann der Nutzer nach Art.
            7
            Abs.
            3 DSGVO jederzeit durch eine Mitteilung an den Plattformbetreiber für die Zukunft
            widerrufen.
          </p>
          <p>Bei dem Aufruf unseres Onlineauftritts auf der Plattform Instagram werden von der
            Facebook
            Ireland Ltd. als Betreiberin der Plattform in der EU Daten des Nutzers (z.B. persönliche
            Informationen, IP-Adresse etc.) verarbeitet.</p>
          <p>Diese Daten des Nutzers dienen zu statistischen Informationen über die Inanspruchnahme
            unserer
            Firmenpräsenz auf Instagram. Die Facebook Ireland Ltd. nutzt diese Daten zu
            Marktforschungs-
            und
            Werbezwecken sowie zur Erstellung von Profilen der Nutzer. Anhand dieser Profile ist es
            der
            Facebook Ireland Ltd. beispielsweise möglich, die Nutzer innerhalb und außerhalb von
            Instagram
            interessenbezogen zu bewerben. Ist der Nutzer zum Zeitpunkt des Aufrufes in seinem
            Account
            auf
            Instagram eingeloggt, kann die Facebook Ireland Ltd. zudem die Daten mit dem jeweiligen
            Nutzerkonto verknüpfen.</p>
          <p>Im Falle einer Kontaktaufnahme des Nutzers über Instagram werden die bei dieser
            Gelegenheit
            eingegebenen personenbezogenen Daten des Nutzers zur Bearbeitung der Anfrage genutzt.
            Die
            Daten
            des Nutzers werden bei uns gelöscht, sofern die Anfrage des Nutzers abschließend
            beantwortet
            wurde und keine gesetzlichen Aufbewahrungspflichten, wie z.B. bei einer anschließenden
            Vertragsabwicklung, entgegenstehen.</p>
          <p>Zur Verarbeitung der Daten werden von der Facebook Ireland Ltd. ggf. auch Cookies
            gesetzt.
          </p>
          <p>Sollte der Nutzer mit dieser Verarbeitung nicht einverstanden sein, so besteht die
            Möglichkeit,
            die Installation der Cookies durch eine entsprechende Einstellung des Browsers zu
            verhindern.
            Bereits gespeicherte Cookies können ebenfalls jederzeit gelöscht werden. Die
            Einstellungen
            hierzu sind vom jeweiligen Browser abhängig. Bei Flash-Cookies lässt sich die
            Verarbeitung
            nicht
            über die Einstellungen des Browsers unterbinden, sondern durch die entsprechende
            Einstellung
            des
            Flash-Players. Sollte der Nutzer die Installation der Cookies verhindern oder
            einschränken,
            kann
            dies dazu führen, dass nicht sämtliche Funktionen von Facebook vollumfänglich nutzbar
            sind.
          </p>
          <p>Näheres zu den Verarbeitungstätigkeiten, deren Unterbindung und zur Löschung der von
            Instagram
            verarbeiteten Daten finden sich in der Datenrichtlinie von Instagram:</p>
          <p><a href="https://help.instagram.com/519522125107875" target="_blank"
              rel="noopener nofollow">https://help.instagram.com/519522125107875</a></p>
          <p>Es ist nicht ausgeschlossen, dass die Verarbeitung durch die Facebook Ireland Ltd. auch
            über
            die
            Meta Platforms, Inc., 1601 Willow Road, Menlo Park, California 94025 in den USA erfolgt.
          </p>
          <h3 class="jet-listing-dynamic-field__content">LinkedIn</h3>
          <p>Wir unterhalten bei LinkedIn eine Onlinepräsenz um unser Unternehmen sowie unsere
            Leistungen
            zu
            präsentieren und mit Kunden/Interessenten zu kommunizieren. LinkedIn ist ein Service der
            LinkedIn Ireland Unlimited Company, Wilton Plaza, Wilton Place, Dublin 2, Irland, ein
            Tochterunternehmen der LinkedIn Corporation, 1000 W. Maude Avenue, Sunnyvale, CA 94085,
            USA.
          </p>
          <p>Insofern weisen wir darauf hin, dass die Möglichkeit besteht, dass Daten der Nutzer
            außerhalb
            der
            Europäischen Union, insbesondere in den USA, verarbeitet werden. Hierdurch können
            gesteigerte
            Risiken für die Nutzer insofern bestehen, als dass z.B. der spätere Zugriff auf die
            Nutzerdaten
            erschwert werden kann. Auch haben wir keinen Zugriff auf diese Nutzerdaten. Die
            Zugriffsmöglichkeit liegt ausschließlich bei LinkedIn.</p>
          <p>Die Datenschutzhinweise von LinkedIn finden Sie unter</p>
          <p><a href="https://www.linkedin.com/legal/privacy-policy" target="_blank"
              rel="noopener nofollow">https://www.linkedin.com/legal/privacy-policy</a></p>
          <h3 class="jet-listing-dynamic-field__content">Allgemeine Verlinkung auf Profile bei
            Drittanbietern
          </h3>
          <p>Der Anbieter setzt auf der Website eine Verlinkung auf die nachstehend aufgeführten
            sozialen
            Netzwerke ein.</p>
          <p>Rechtsgrundlage ist hierbei Art. 6 Abs. 1 lit. f DSGVO. Das berechtigte Interesse des
            Anbieters
            besteht an der Verbesserung der Nutzungsqualität der Website.</p>
          <p>Die Einbindung der Plugins erfolgt dabei über eine verlinkte Grafik oder Text. Erst durch
            einen
            Klick auf
            die entsprechende Grafik oder den Text wird der Nutzer zu dem Dienst des jeweiligen
            sozialen
            Netzwerks
            weitergeleitet.</p>
          <p>Nach der Weiterleitung des Kunden werden durch das jeweilige Netzwerk Informationen über
            den
            Nutzer erfasst. Dies sind zunächst Daten wie IP-Adresse, Datum, Uhrzeit und besuchte
            Seite.
            Ist
            der Nutzer währenddessen in seinem Benutzerkonto des jeweiligen Netzwerks eingeloggt,
            kann
            der
            Netzwerk-Betreiber ggf. die gesammelten Informationen des konkreten Besuches des Nutzers
            dem
            persönlichen Account des Nutzers zuordnen. Interagiert der Nutzer über einen
            „Teilen“-Button
            des
            jeweiligen Netzwerks, können diese Informationen in dem persönlichen Benutzerkonto des
            Nutzers
            gespeichert und ggf. veröffentlicht werden. Will der Nutzer verhindern, dass die
            gesammelten
            Informationen unmittelbar seinem Benutzerkonto zugeordnet werden, muss sich der Nutzer
            vor
            dem
            Anklicken der Grafik oder des Textes ausloggen. Zudem besteht die Möglichkeit, das
            jeweilige
            Benutzerkonto
            entsprechend zu konfigurieren.</p>
          <p>Folgende soziale Netzwerke sind vom Anbieter verlinkt:</p>
          <h4 class="jet-listing-dynamic-field__content">Instagram</h4>
          <p>Meta Platforms Ireland Limited, 4 Grand Canal Square, Dublin 2, Irland.</p>
          <p>Datenschutzerklärung: <a href="https://help.instagram.com/519522125107875" target="_blank"
              rel="noopener nofollow">https://help.instagram.com/519522125107875</a></p>
          <h4 class="jet-listing-dynamic-field__content">LinkedIn</h4>
          <p>LinkedIn Ireland Unlimited Company, Wilton Plaza, Wilton Place, Dublin 2, Irland, ein
            Tochterunternehmen der LinkedIn Corporation, 1000 W. Maude Avenue, Sunnyvale, CA 94085
            USA.
          </p>
          <p>Datenschutzerklärung: <a href="https://www.linkedin.com/legal/privacy-policy" target="_blank"
              rel="noopener nofollow">https://www.linkedin.com/legal/privacy-policy</a></p>
          <h3 class="jet-listing-dynamic-field__content">Matomo</h3>
          <p>Diese Website benutzt den Open Source Webanalysedienst Matomo. <br><br>
            Mit Hilfe von Matomo sind wir in der Lage Daten über die Nutzung unserer Website durch
            die
            Websitebesucher zu erfassen und zu analysieren. Hierdurch können wir u. a. herausfinden,
            wann
            welche
            Seitenaufrufe getätigt wurden und aus welcher Region sie kommen. Außerdem erfassen wir
            verschiedene
            Logdateien (z. B. IP-Adresse, Referrer, verwendete Browser und Betriebssysteme) und
            können
            messen, ob
            unsere Websitebesucher bestimmte Aktionen durchführen (z. B. Klicks, Käufe u.
            Ä.).<br><br>
            Die Nutzung dieses Analyse-Tools erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO.
            Der
            Websitebetreiber hat ein berechtigtes Interesse an der Analyse des Nutzerverhaltens, um
            sowohl
            sein
            Webangebot als auch seine Werbung zu optimieren. Sofern eine entsprechende Einwilligung
            abgefragt
            wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a
            DSGVO
            und
            § 25 Abs. 1
            TTDSG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf
            Informationen im
            Endgerät des Nutzers (z. B. Device-Fingerprinting) im Sinne des TTDSG umfasst. Die
            Einwilligung
            ist
            jederzeit widerrufbar.</p>
          <h4>IP-Anonymisierung</h4>
          <p>Bei der Analyse mit Matomo setzen wir IP-Anonymisierung ein. Hierbei wird Ihre IP-Adresse
            vor
            der
            Analyse gekürzt, sodass Sie Ihnen nicht mehr eindeutig zuordenbar ist.</p>
          <h4>Cookielose Analyse</h4>
          <p>Wir haben Matomo so konfiguriert, dass Matomo keine Cookies in Ihrem Browser speichert.
          </p>
          <h4>Hosting</h4>
          <p>Wir hosten Matomo extern bei dem Hoster: <br><br>
            STRATO AG<br>
            Otto-Ostrowski-Straße 7<br>
            10249 Berlin
          </p>
          <p><a href="https://www.generator-datenschutzerklärung.de" target="_blank"
              rel="noopener">Muster-Datenschutzerklärung</a> der <a
              href="https://www.ratgeberrecht.eu/datenschutz/datenschutzerklaerung-generator-dsgvo.html" target="_blank"
              rel="noopener">Anwaltskanzlei Weiß &amp; Partner</a> mit elementen
            der
            generierten
            Datenschutzerklärung von <a href="https://www.e-recht24.de">e-recht24.</a></p>
</template>
    
<script>
export default {
    name: 'PrivacyPolicyGerman',
    mounted() {
    },
    methods: {
    }
};
</script>
    
<style scoped lang="scss">
@import './style.scss';
</style>