import * as THREE from 'three';

export function createStars(scene, numStars, sceneSize = 0.3) {
    // Function to generate a random number within a specified range
    function getRandomInRange(min, max) {
        return Math.random() * (max - min) + min;
    }

    function checkIsMobileDevice() {
        const isWindowNarrow = window.innerWidth < 600;
        const isphoneFlipped = window.innerHeight < 600;

        if (isWindowNarrow || isphoneFlipped) {
            return true;
        }
        return false;
    }

    numStars = 1200;

    if (window.innerWidth > 600 && window.innerWidth < 1400 ) {
        numStars = 600;
    } else if (window.innerWidth < 600) {
        numStars = 400;
    }

    // Create a group to hold all the spheres
    const starsGroup = new THREE.Group();

    // Create stars
    for (let i = 0; i < numStars; i++) {


        let rangeA = 0.0001;
        let rangeB = 0.002;

        if (checkIsMobileDevice()) {
            rangeA = 0.0001;
            rangeB = 0.004;
        }

        // Generate random sizes within the range 0.001 - 0.003
        const randomSize = getRandomInRange(rangeA, rangeB);

        const geometrySphere = new THREE.SphereGeometry(randomSize, 6, 6);
        const materialSphere = new THREE.MeshPhongMaterial({ color: 'rgb(173, 216, 230)' });
        const sphere = new THREE.Mesh(geometrySphere, materialSphere);

        // Randomly position the stars within the scene
        const x = getRandomInRange(-sceneSize, sceneSize);
        const y = getRandomInRange(-sceneSize, sceneSize);
        const z = getRandomInRange(-sceneSize, sceneSize);

        sphere.position.set(x * Math.sin(Math.random()) * 5, y * Math.sin(Math.random()) * 5, z * Math.sin(Math.random()) * 5);

        starsGroup.add(sphere); // Add each sphere to the group
    }

    // Enable shadows for the starsGroup
    starsGroup.castShadow = true;
    starsGroup.receiveShadow = true;

    // Add the group to the provided scene
    scene.add(starsGroup);

    return starsGroup;
}