<!-- ProjectSingleViewMobile -->
<template>
    <div>
        <div class="backgroundImage">
            <img :src="projectData.length > 0 ? projectData[0].image : ''" alt="">
        </div>

        <div class="projects-container">

                <div class="projects-text-container" ref="projectsTextContainer">
                    <div class="projects-title" v-html="projectTitle"></div>
                    <div class="projects-description" v-html="projectDescription"></div>
                    <div class="projects-role-and-credit">
                        <div class="projects-role-credit-container">
                            <div class="projects-role-credit-title">MY ROLE</div>
                            <div class="projects-role-credit-text">
                                <ul>
                                    <li v-for="(item, index) in roleCreditList" :key="index" v-html="item"></li>
                                </ul>
                            </div>
                        </div>
                        <div class="projects-role-credit-container">
                            <div class="projects-role-credit-title">CREDITS</div>
                            <div class="projects-role-credit-text">
                                <ul>
                                    <li v-for="(item, index) in creditsList" :key="index" v-html="item"></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container-img-mob">
                    <div class="start-spacer" ref="startSpacer">

                        <div class="img-projects" v-for="(item, index) in projectData" :key="index">
                            <img :src="item.image" alt="">
                        </div>

                        <router-link :to="{ path: nextProjectLink }" class="router-link">
                            <div class="next-project" v-html="nextProject"></div>
                        </router-link>
                    </div>
                </div>

         
        </div>
    </div>

</template>
  
<script>



export default {
    name: 'ProjectSingleViewMobile',
    props: {
        projectTitle: {
            type: String,
            default: 'Default Project Title',
        },
        projectDescription: {
            type: String,
            default: 'Default Project Description',
        },
        projectName: {
            type: String,
            default: 'Project Name',
        },
        roleCreditList: {
            type: Array,
            default: () => [
                '<li>item</li>',
                '<li>item</li>',
                '<li>item</li>',
                '<li>item</li>',
            ],
        },
        creditsList: {
            type: Array,
            default: () => [
                '<li>item</li>',
                '<li>item</li>',
                '<li>item</li>',
                '<li>item</li>',
            ],
        },
        projectData: {
            type: Array,
            required: true,
        },
        nextProjectLink: {
            type: String,
            required: true,
        },
        nextProject: {
            type: String,
            required: true,
        },
    },
    data() {
        return {

        };
    },
    mounted() {
    },
    methods: {
    },
};
</script>
  
<style scoped lang="scss">
@import './style-mobile.scss';
</style>