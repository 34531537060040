export function createTweenAnimations(THREE, TWEEN, context) {
    return {
      // Tween to bring mesh to the about position
      tweenToAbout() {
        new TWEEN.Tween(context.mesh.position)
          .to({ x: 0, y: 0, z: 0 }, 10)
          .easing(TWEEN.Easing.Ease)
          .start();
      },
  
      // Tween to rotate mesh to end position
      tweenToAboutEnd() {
        context.counter++;
        context.canvasElement.style.opacity = 1;
  
        new TWEEN.Tween(context.mesh.rotation)
          .to({ x: 0, y: (Math.PI / 2) * context.counter, z: 0 }, 3400)
          .easing(TWEEN.Easing.Elastic.Out)
          .start();
      },
  
      // Tween for mobile version to rotate and scale the mesh
      tweenToAboutEndMobile() {
        context.counter++;
        context.canvasElement.style.opacity = 1;
  
        new TWEEN.Tween(context.mesh.rotation)
          .to({ x: 0, y: (Math.PI / 2) * context.counter, z: 0 }, 3400)
          .easing(TWEEN.Easing.Elastic.Out)
          .start();
  
        new TWEEN.Tween(context.mesh.scale)
          .to({ x: 1.4, y: 1.4, z: 1.4 }, 1600)
          .easing(TWEEN.Easing.Elastic.Out)
          .start();
  
        context.emitAboutEndMobile();
      },
  
      // Tween for scrolling down
      tweenAboutScrollDown() {
        context.counter++;
        new TWEEN.Tween(context.mesh.rotation)
          .to({ x: 0, y: (Math.PI / 2) * context.counter, z: 0 }, 3400)
          .easing(TWEEN.Easing.Elastic.Out)
          .start();
      },

      // Tween for scrolling up
      tweenAboutScrollUp() {
        context.counter--;
        new TWEEN.Tween(context.mesh.rotation)
          .to({ x: 0, y: (Math.PI / 2) * context.counter, z: 0 }, 3400)
          .easing(TWEEN.Easing.Elastic.Out)
          .start();
      },
  
      // Tween to fade out the cube on leave
      tweenAboutLeave() {
        context.canvasElement.style.opacity = 0;
      }
    };
  }
  