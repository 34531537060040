import * as THREE from 'three';
import * as CANNON from 'cannon-es';
import { lights } from './scripts/lights.js';

export default class PhysicsCubeConstructor {
  constructor(container) {
    this.container = container;

    // Three.js variables
    this.camera = null;
    this.scene = null;
    this.renderer = null;
    this.movementPlane = null;
    this.clickMarker = null;
    this.cubeMesh = null;

    // Cannon.js variables
    this.world = null;
    this.jointBody = null;
    this.jointConstraint = null;
    this.cubeBody = null;
    this.wireframeMesh = null;
    this.floorBody = null;

    this.trigger = 1;
    this.isDragging = false;
    this.fixedConstraintPosition = new CANNON.Vec3(0.3, 0, 0.3);

    // Arrays for syncing Cannon.js and Three.js
    this.meshes = [];
    this.bodies = [];

    this.isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;

    this.initThree();
    this.initCannon();
    this.setupEventListeners();
    this.animate();
  }

  initThree() {
    this.camera = new THREE.PerspectiveCamera(30, window.innerWidth / window.innerHeight, 0.5, 1000);
    this.camera.position.set(0, 2, 10);

    this.scene = new THREE.Scene();
    this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.container.appendChild(this.renderer.domElement);

    // Add lights to the scene
    lights(this.scene, THREE);

    // Click marker
    const markerGeometry = new THREE.CircleGeometry(0.04, 32);
    const markerMaterial = new THREE.MeshPhongMaterial({
      color: 'rgb(255, 255, 255)',
      opacity: 1,
      transparent: true,
    });
    this.clickMarker = new THREE.Mesh(markerGeometry, markerMaterial);
    this.clickMarker.visible = false;
    this.scene.add(this.clickMarker);

    // Cube mesh
    const cubeGeometry = new THREE.BoxGeometry(0.5, 0.5, 0.5);
    const cubeMaterial = new THREE.MeshPhongMaterial({
      color: 0xffffff,
      opacity: 0.8,
      transparent: true,
    });
    this.cubeMesh = new THREE.Mesh(cubeGeometry, cubeMaterial);
    this.meshes.push(this.cubeMesh);
    this.scene.add(this.cubeMesh);

    // Wireframe for cube
    const edges = new THREE.EdgesGeometry(cubeGeometry);
    const wireframeMaterial = new THREE.LineBasicMaterial({ color: 0xffffff, linewidth: 2 });
    this.wireframeMesh = new THREE.LineSegments(edges, wireframeMaterial);
    this.scene.add(this.wireframeMesh);

    // Movement plane
    const planeGeometry = new THREE.PlaneGeometry(100, 100);
    const floorMaterial = new THREE.MeshLambertMaterial({
      color: 0xffffff,
      transparent: true,
      opacity: 1,
    });
    this.movementPlane = new THREE.Mesh(planeGeometry, floorMaterial);
    this.movementPlane.visible = false;
    this.scene.add(this.movementPlane);

    window.addEventListener('resize', this.onWindowResize.bind(this));
  }

  onWindowResize() {
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(window.innerWidth, window.innerHeight);
  }

  initCannon() {
    // Initialize Cannon.js world, bodies, and constraints
    this.world = new CANNON.World();
    this.world.gravity.set(0, -10, 0);

    // Floor
    const floorShape = new CANNON.Plane();
    this.floorBody = new CANNON.Body({ mass: 0 });
    this.floorBody.addShape(floorShape);
    this.floorBody.quaternion.setFromEuler(-Math.PI / 2, 0, 0);
    this.world.addBody(this.floorBody);

    // Cube
    const cubeShape = new CANNON.Box(new CANNON.Vec3(0.25, 0.25, 0.25));
    this.cubeBody = new CANNON.Body({ mass: 5 });
    this.cubeBody.addShape(cubeShape);
    this.cubeBody.position.set(0, 1, 0);

    const initialRotation = new CANNON.Quaternion();
    initialRotation.setFromAxisAngle(new CANNON.Vec3(0, 1, 0), Math.PI / 4);
    this.cubeBody.quaternion.copy(initialRotation);
    this.cubeBody.linearDamping = 0.9;
    this.cubeBody.angularDamping = 0.9;
    this.bodies.push(this.cubeBody);
    this.world.addBody(this.cubeBody);

    // Joint body for constraints
    const jointShape = new CANNON.Sphere(0.1);
    this.jointBody = new CANNON.Body({ mass: 0 });
    this.jointBody.addShape(jointShape);
    this.jointBody.collisionFilterGroup = 0;
    this.jointBody.collisionFilterMask = 0;
    this.world.addBody(this.jointBody);
  }

  setupEventListeners() {
    const moveEvent = this.isTouchDevice ? 'touchmove' : 'pointermove';
    window.addEventListener(moveEvent, (event) => {
      setTimeout(() => {
        requestAnimationFrame(() => {
          this.isDragging = true;
        });
      }, 300);

      if (!this.isDragging) {
        return;
      }

      const clientX = this.isTouchDevice ? event.touches[0].clientX : event.clientX;
      const clientY = this.isTouchDevice ? event.touches[0].clientY : event.clientY;

      const hitPoint = this.getHitPoint(clientX, clientY);
      if (this.trigger === 1) {
        this.initializeDrag(hitPoint);
        this.trigger = 0;
      }

      if (hitPoint) {
        this.moveClickMarker(hitPoint);
        this.moveJoint(hitPoint);
      }
    });
  }

  getHitPoint(clientX, clientY) {
    const mouse = new THREE.Vector2();
    mouse.x = (clientX / window.innerWidth) * 2 - 1;
    mouse.y = -((clientY / window.innerHeight) * 2 - 1);

    const raycaster = new THREE.Raycaster();
    raycaster.setFromCamera(mouse, this.camera);
    const intersects = raycaster.intersectObject(this.movementPlane);

    return intersects.length > 0 ? intersects[0].point : undefined;
  }

  initializeDrag(hitPoint) {
    this.moveClickMarker(hitPoint || new THREE.Vector3(0.5, 0.5, 0.9));
    this.moveMovementPlane(hitPoint, this.camera);
    this.addJointConstraint(this.cubeBody);
    this.isDragging = true;
  }

  moveClickMarker(position) {
    this.clickMarker.position.copy(position);
  }

  moveMovementPlane(point, camera) {
    this.movementPlane.position.copy(point);
    this.movementPlane.quaternion.copy(camera.quaternion);
  }

  addJointConstraint(constrainedBody) {
    const vector = this.fixedConstraintPosition.vsub(constrainedBody.position);
    const antiRotation = constrainedBody.quaternion.inverse();
    const pivot = antiRotation.vmult(vector);
    this.jointBody.position.copy(this.fixedConstraintPosition);
    this.jointConstraint = new CANNON.PointToPointConstraint(
      constrainedBody,
      pivot,
      this.jointBody,
      new CANNON.Vec3(0, 0, 0)
    );
    this.world.addConstraint(this.jointConstraint);
  }

  moveJoint(position) {
    this.jointBody.position.copy(position);
    this.jointConstraint.update();
  }

  animate() {
    const animateFrame = () => {
      requestAnimationFrame(animateFrame);

      this.world.step(1 / 60);

      // Sync Three.js meshes with Cannon.js bodies
      this.wireframeMesh.position.copy(this.cubeMesh.position);
      this.wireframeMesh.rotation.copy(this.cubeMesh.rotation);

      for (let i = 0; i < this.meshes.length; i++) {
        this.meshes[i].position.copy(this.bodies[i].position);
        this.meshes[i].quaternion.copy(this.bodies[i].quaternion);
      }

      this.renderer.render(this.scene, this.camera);
    };
    animateFrame();
  }
}
