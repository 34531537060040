<template>
  <nav v-if="!$checkIsMobileDevice()" ref="nav">
    <router-link to="/" class="nav-block-left" @click="disableClicking">
      <div class="text-home" ref="textHomeInner">MATTHIAS <br> HECKEL</div>
    </router-link>

    <div class="nav-block-right">
      <router-link to="/about" class="nav-point about" :class="{ 'active-link': isCurrentRoute('/about') }"
        @click="disableClicking">ABOUT</router-link>
      <router-link to="/projects" class="nav-point" :class="{ 'active-link': isCurrentRoute('/projects') }"
        @click="disableClicking">PROJECTS</router-link>
      <router-link to="/contact" class="nav-point" :class="{ 'active-link': isCurrentRoute('/contact') }"
        @click="disableClicking">CONTACT</router-link>
    </div>
  </nav>

  <div v-else>
    <!-- Actions Mobile -->
    <div class="menu-icon" @click="toggleMobileMenu">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line id="line" x1="1.62305" y1="8.07544" x2="22.623" y2="8.07544" stroke="white" stroke-width="2" />
        <line id="lineTwo" x1="1.62305" y1="15.0754" x2="22.623" y2="15.0754" stroke="white" stroke-width="2" />
      </svg>
    </div>

    <!-- Menu layer Mobile-->
    <div v-if="isMobileMenuOpen" class="menu-layer-mobile">
      <div class="menu-layer-mobile-inner">
        <div class="menu-points-container">
          <router-link to="/" class="nav-mobile" @click="closeMobileMenu">Home</router-link>
          <router-link to="/about" class="nav-mobile" @click="closeMobileMenu">About</router-link>
          <router-link to="/projects" class="nav-mobile" @click="closeMobileMenu">Projects</router-link>
          <router-link to="/contact" class="nav-mobile" @click="closeMobileMenu">Contact</router-link>
        </div>
        <div class="close-icon" @click="closeMobileMenu">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="4.56258" y1="3.86833" x2="19.4118" y2="18.7176" stroke="white" stroke-width="2" />
            <line x1="4.58782" y1="18.7176" x2="19.4371" y2="3.86833" stroke="white" stroke-width="2" />
          </svg>
        </div>
      </div>
    </div>

  </div>
</template>
  
<script>
export default {
  name: 'NavBar',
  data() {
    return {
      isMobileMenuOpen: false,
    };
  },
  mounted() {
    window.addEventListener('resize', this.$checkIsMobileDevice);
  },
  methods: {
    disableClicking() {
      const nav = this.$refs.nav;
      nav.style.pointerEvents = 'all';

      setTimeout(() => {
        nav.style.pointerEvents = 'all';
        }, 1000);
     
    },
    isCurrentRoute(route) {
      return this.$route.path === route;
    },
    toggleMobileMenu() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
    },
    closeMobileMenu() {
      this.isMobileMenuOpen = false;
    },
  }
};

</script>
  
<style lang="scss"> @import './style.scss';
 @import './style-mobile.scss';

 .active-link::before {
   width: 100%;
 }
</style>