<template>
  <div class="background-container" ref="backgroundContainer"></div>

  <div class="content-container" ref="contentContainer">
    <div class="text" ref="text">
      <div class="text-inner" ref="textInner">
        MATTHIAS <br> HECKEL
      </div>
    </div>
  </div>
</template>
  
<script>
import { checkIsTabletDevice } from '@/scripts/utils';

export default {
  name: 'SplashscreenPage',
  mounted() {
    // Logo animation
    const textInner = this.$refs.textInner;
    const backgroundContainer = this.$refs.backgroundContainer;
    const contentContainer = this.$refs.contentContainer;

    if (checkIsTabletDevice()) {
      setTimeout(() => {
        textInner.style.transition = 'opacity 0.5s ease';
        textInner.style.opacity = '0';
      }, 1000);
    } else {
      setTimeout(() => {
        textInner.style.transition = 'width 0.5s ease';
        textInner.style.width = '0px'
      }, 1000);
    }

    setTimeout(() => {

    }, 1000);

    setTimeout(() => {
      backgroundContainer.style.transition = 'opacity 0.5s ease';
      backgroundContainer.style.opacity = '0';
    }, 1800);

    setTimeout(() => {
      contentContainer.style.visibility = 'hidden';
      backgroundContainer.style.visibility = 'hidden';
    }, 2300);
  },
};
</script>
  
<style scoped lang="scss">
@import './style.scss';
</style>