<template>
  <!-- Desktop View -->
  <div v-if="!$checkIsMobileDevice()">
    <div class="container" ref="container" @click="handleClick($event)" @scroll="scrollMove"
      @mousemove="handleHover($event)">

      <div class="container-select" ref="containerSelect">
        <div v-for="(image, index) in images" :key="'image-' + index" class="select" ref="select">
        </div>
      </div>

      <div class="container-images" ref="containerImages">
        <div v-for="(image, index) in images" :key="'image-' + index" class="images" ref="images">
          <img :src="image.src" :alt="image.alt" class="image-inner" />
        </div>
      </div>

      <div class="container-text" ref="containerText">
        <div v-for="(text, index) in texts" :key="'text-' + index" class="text">
          <div class="projects-title-container">
            <div class="h1-tag">
              <router-link :to="text.customPath" class="link">
                <h1 ref="h1">{{ text.title }}</h1>
              </router-link>
            </div>
            <div class="h2-tag">
              <h2>{{ text.subtitle }}</h2>
            </div>
          </div>
        </div>
      </div>

      <div class="container-inner" ref="containerInner"></div>
    </div>
  </div>

  <!-- Mobile View -->
  <div v-else-if="isLoaded">
    <div class="projects-preview-layer-mob" ref="containerMob">
      <div class="projects-preview-layer-inner-mob">

        <router-link v-for="(item, index) in mobileItems" :key="'router-link-' + index" :to="{ path: item.customPath }"
          class="router-link">
          <div class="img-container">
            <div class="border-container">
              <img :src="item.imageSrc" class="img-mob" :alt="item.alt">
            </div>
            <div class="projects-title-container">
              <h1>{{ item.title }}</h1>
              <h2>{{ item.subtitle }}</h2>
            </div>
          </div>
        </router-link>
        <div class="spacer"></div>
      </div>
    </div>
  </div>
  <LoadingSpinner v-if="isLoadingSpinner" />
  <PhysicsCube v-if="isPhysicsCube" />
</template>
  
<script>
import { onMounted } from 'vue';
import { useTitle } from '@/scripts/title';
import PhysicsCube from '@/components/templates/PhysicsCube/PhysicsCube.vue';
import LoadingSpinner from '@/components/templates/LoadingSpinner/LoadingSpinner.vue';

export default {
  data() {
    return {
      isLoadingSpinner: true,
      isPhysicsCube: false,
      isLoaded: false,
      isTouchDevice: false,
      images: [
        { src: "/images/projects-prev/p01_img_00.jpg", alt: "Scene of the virtual exhibition Iconoclash" },
        { src: "/images/projects-prev/p02_img_00.jpg", alt: "Scene of the virtual exhibition Les Immatériaux" },
        { src: "/images/projects-prev/p03_img_00.jpg", alt: "Design Mockups of Trademachines" },
        { src: "/images/projects-prev/p04_img_00.jpg", alt: "Scene of the Generic Exhibition Platform" },
      ],
      texts: [
        { title: 'ICONOCLASH', subtitle: 'Beyond Matter (ZKM Karlsruhe)', customPath: '/projects/iconoclash' },
        { title: 'LES IMMATERIAUX', subtitle: 'Beyond Matter (ZKM Karlsruhe)', customPath: '/projects/lesimmateriaux' },
        { title: 'TRADEMACHINES', subtitle: 'Trademachines Fi GmbH', customPath: '/projects/trademachines' },
        { title: 'GENERIC EXHIBITION PLATFORM', subtitle: 'Beyond Matter (ZKM Karlsruhe)', customPath: '/projects/genericexhibitionplatform' },
      ],
      mobileItems: [
        { title: 'ICONOCLASH', subtitle: 'Beyond Matter (ZKM Karlsruhe)', customPath: '/projects/iconoclash', imageSrc: '/images/mobile/p01/p01_img_00.jpg', alt: 'Scene of the virtual exhibition Iconoclash' },
        { title: 'LES IMMATÉRIAUX', subtitle: 'Beyond Matter (ZKM Karlsruhe)', customPath: '/projects/lesimmateriaux', imageSrc: '/images/mobile/p02/p02_img_00.jpg', alt: 'Scene of the virtual exhibition Les Immatériaux' },
        { title: 'TRADEMACHINES PLATFORM', subtitle: 'Trademachines Fi GmbH', customPath: '/projects/trademachines', imageSrc: '/images/mobile/p03/p03_img_00.jpg', alt: 'Design Mockups of Trademachines' },
        { title: 'GENERIC EXHIBITION PLATFORM', subtitle: 'Beyond Matter (ZKM Karlsruhe)', customPath: '/projects/genericexhibitionplatform', imageSrc: '/images/mobile/p04/p04_img_00.jpg', alt: 'Scene of the Generic Exhibition Platform' },
      ],
    };
  },
  components: {
    PhysicsCube,
    LoadingSpinner
  },
  mounted() {
    document.dispatchEvent(new Event('render-event'));
    this.isLoaded = true;

    if (!this.$checkIsMobileDevice()) {
      this.$nextTick(() => {
        this.showContainerDesktop();
        this.setDimensions();
      });
    }

    if (this.$checkIsMobileDevice()) {
      this.$nextTick(() => {
        this.showContainerMobile();
      });
    }
    this.detectTouchDevice();
  },
  name: 'ProjectsPage',
  beforeRouteLeave(to, from, next) {
    if (!this.$checkIsMobileDevice()) {
      this.hideContainer();
    }
    return new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, 500);
    }).then(() => {
      next();
    });
  },
  setup() {
    const titleState = useTitle();
    onMounted(() => {
      titleState.title = 'Matthias Heckel - Projects';
    });
    return {};
  },
  methods: {
    showContainerMobile() {
      const containerMob = this.$refs.containerMob;
      const imagesToLoad = this.mobileItems.map(item => item.imageSrc);
      let loadedImages = 0;

      const handleImageLoad = () => {
        loadedImages++;

        if (loadedImages === imagesToLoad.length) {
          containerMob.style.transition = 'opacity 0.5s ease';
          containerMob.style.opacity = '1';
          this.isLoadingSpinner = false;
        }
      };
      imagesToLoad.forEach(src => {
        const img = new Image();
        img.src = src;
        img.onload = handleImageLoad;
      });
    },
    showContainerDesktop() {
      const container = this.$refs.container;
      const imagesToLoad = this.images.map(item => item.src);

      const containerImages = this.$refs.containerImages;
      const containerText = this.$refs.containerText;
      const containerSelect = this.$refs.containerSelect;

      let loadedImages = 0;

      const handleImageLoad = () => {
        loadedImages++;

        if (loadedImages === imagesToLoad.length) {
          container.style.transition = 'opacity 0.5s ease';
          container.style.opacity = '1';

          containerText.style.left = 0 + 'px';
          containerImages.style.left = 0 + 'px';
          containerSelect.style.left = 0 + 'px';

          this.isLoadingSpinner = false;
        }
      };
      imagesToLoad.forEach(src => {
        const img = new Image();
        img.src = src;
        img.onload = handleImageLoad;
      });
    },
    setDimensions() {
      const containerImages = this.$refs.containerImages;
      const containerInner = this.$refs.containerInner;
      containerInner.style.height = containerImages.clientWidth + window.innerHeight + 'px';
      containerInner.style.width = containerImages.clientWidth + window.innerHeight + 'px';
    },
    detectTouchDevice() {
      this.isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
    },
    scrollMove() {
      const containerImages = this.$refs.containerImages;
      const containerText = this.$refs.containerText;
      const containerSelect = this.$refs.containerSelect;
      const container = this.$refs.container;

      if (!this.isTouchDevice) {
        containerText.style.left = - container.scrollTop + 'px';
        containerImages.style.left = - container.scrollTop + 'px';
        containerSelect.style.left = - container.scrollTop + 'px';
      }
      if (this.isTouchDevice) {
        containerText.style.left = - container.scrollLeft + 'px';
        containerImages.style.left = - container.scrollLeft + 'px';
        containerSelect.style.left = - container.scrollLeft + 'px';
      }

    },
    handleClick(event) {
      const x = event.clientX;
      const y = event.clientY;
      const elementsAtPoint = document.elementsFromPoint(x, y);
      document.body.style.cursor = 'default';

      let imageInnerElements = Array.from(elementsAtPoint).filter(element => element.classList.contains('select'));

      if (imageInnerElements.length > 0) {
        const hoveredImage = imageInnerElements[0];

        // Convert the NodeList to an array before using indexOf
        const imageInnerIndex = Array.from(document.querySelectorAll('.select'));
        const indexImage = imageInnerIndex.indexOf(hoveredImage);
        if (indexImage === 0) {
          this.$router.push(this.texts[0].customPath);
        }
        if (indexImage === 1) {
          this.$router.push(this.texts[1].customPath);
        }
        if (indexImage === 2) {
          this.$router.push(this.texts[2].customPath);
        }
        if (indexImage === 3) {
          this.$router.push(this.texts[3].customPath);
        }
      }
    },
    handleHover(event) {
      const x = event.clientX;
      const y = event.clientY;
      const elementsAtPoint = document.elementsFromPoint(x, y);
      const images = this.$refs.images;
      const texts = this.$refs.containerText.querySelectorAll('.text');
      const h1s = this.$refs.containerText.querySelectorAll('h1');
      const h2s = this.$refs.containerText.querySelectorAll('h2');

      // Image Elements
      let imageInnerElements = Array.from(elementsAtPoint).filter(element => element.classList.contains('select'));

      if (imageInnerElements.length > 0) {
        const hoveredImage = imageInnerElements[0];

        // Convert the NodeList to an array before using indexOf
        const imageInnerIndex = Array.from(document.querySelectorAll('.select'));
        const indexImage = imageInnerIndex.indexOf(hoveredImage);


        // Set all images to "-60px" or "0px" based on indexImage
        images.forEach((image, index) => {
          const select = document.querySelector(`.select:nth-child(${index + 1})`);
          const text = texts?.[index];
          const h1 = h1s?.[index];
          const h2 = h2s?.[index];


          if (indexImage !== -1 && indexImage === index) {
            document.body.style.cursor = 'pointer';
            image.style.transform = "rotateZ(8deg) scale(1.1)";
            image.style.border = "12px solid transparent";
            image.style.zIndex = "2";

            h1.style.color = "rgb(255, 255, 255)";

            h2.style.color = "transparent";

            text.style.transform = "skewY(-10deg) scale(3.5) translateX(230px) translateY(-85px)";


            select.style.filter = "blur(0px)";
          } else {

            image.style.transform = "rotateZ(0deg) scale(1)";
            image.style.border = "12px solid white";
            image.style.zIndex = "0";


            h1.style.color = "white";
            h2.style.color = "white";

            text.style.transform = "skewY(0deg) scale(1) translateX(0px)";

            select.style.filter = "blur(0px)";
          }
        });

      } else {
        // Reset all images to "0px" if not hovering over any "image-inner" element
        images.forEach((image, index) => {
          document.body.style.cursor = 'default';
          const select = document.querySelector(`.select:nth-child(${index + 1})`);
          const text = texts?.[index];  // Access the individual text element
          const h1 = h1s?.[index];
          const h2 = h2s?.[index];

          image.style.transform = "rotateZ(0deg) scale(1)";
          image.style.border = "12px solid white";
          image.style.zIndex = "0";

          h1.style.color = "white";
          h2.style.color = "white";

          text.style.transform = "skewY(0deg) scale(1) translateX(0px)";

          select.style.filter = "blur(0px)";
        });
      }
    },
    rotateCube() {
      this.$store.dispatch('setProjectsPageHover', true);
    },
    hideContainer() {
      const container = this.$refs.container;
      container.style.transition = 'opacity 0.3s ease';
      container.style.opacity = '0';
    },
  },

};
</script>
  
<style scoped lang="scss">
@import './style.scss';
@import './style-mobile.scss';

body {
  overflow: scroll;
}
</style>