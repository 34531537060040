<template>
    <div class="background" ref="background"></div>
</template>
    
<script>
import { checkIsMobileDevice } from '@/scripts/utils';

export default {
    name: 'BackgroundTemp',
    mounted() {
        setTimeout(() => {
            this.createBorder();
        }, 2000);
    },
    methods: {
        createBorder() {
            const background = this.$refs.background;
            if (!checkIsMobileDevice()) {
                if (window.innerWidth < 1000) {
                    background.style.border = '16px solid white';
                } else if (window.innerWidth > 1000) {
                    background.style.border = '20px solid white';
                }
            } else {
                background.style.border = '0px solid white';
            }
        },
    }
};
</script>
    
<style scoped lang="scss">
@import './style.scss';
</style>