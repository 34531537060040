<template>
  <div>
    <div class="canvas-physics-cube" ref="canvasPhysicsCube"></div>
  </div>
</template>

<script>
// Fix the import to default
import PhysicsCubeConstructor from './physicsCubeConstructor';

export default {
  name: 'PhysicsCube',
  mounted() {
    const canvasPhysicsCube = this.$refs.canvasPhysicsCube;

    // Use the default export constructor
    this.threeJSInstance = new PhysicsCubeConstructor(canvasPhysicsCube);
  },
};
</script>

<style>
.canvas-physics-cube {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0 !important;
}
</style>
