<template>
    <!-- <div class></div> -->
    <div class="content-container" ref="contentContainer">
        <div class="content-container-inner" ref="contentContainerInner">

            <div class="header" ref="header">
                <div class="container-left-header">
                    <h1 ref="h1" v-html="headerText"></h1>
                </div>
                <div class="container-right-header" ref="containerRightHeader"></div>
            </div>

            <div class="sub-header" ref="subHeader">
                <div class="container-left-sub-header">
                    <h2 ref="h2" v-html="subHeaderText"></h2>
                </div>
                <div class="container-right-sub-header" ref="containerRightSubHeader"></div>
            </div>

            <div class="text" ref="text">
                <div class="container-left-text">
                    <h3 ref="h3" v-html="mainText"></h3>
                </div>
                <div class="container-right-text" ref="containerRightText"></div>
            </div>
        </div>
    </div>
</template>
  
<script>
import { watch } from 'vue';

export default {
    data() {
        return {
        };
    },
    mounted() {
        watch(() => this.$store.getters.isTextContentSlide, (newValue) => {
            this.isTextContentSlide = newValue;
            if (newValue) {
                this.changeSite();
                this.$store.commit('setTextContentSlide', false);
            }
        });
    },
    name: 'SlidesText',
    props: {
        headerText: String,
        subHeaderText: String,
        mainText: String,
    },
    methods: {
        startAnimation() {
            const header = this.$refs.header;
            const subHeader = this.$refs.subHeader;
            const text = this.$refs.text;
            const startTime = 300;
            this.calculatingHeight();

            // Animation for header
            setTimeout(() => {
                header.style.transition = 'margin-left 0.3s cubic-bezier(.16,.66,0,.99)';
                header.style.marginLeft = '0px';
            }, startTime);

            // Animation for sub-header
            setTimeout(() => {
                subHeader.style.transition = 'margin-left 0.3s cubic-bezier(.16,.66,0,.99)';
                subHeader.style.marginLeft = '0px';
            }, startTime + 400);

            // Animation for text
            setTimeout(() => {

                if (window.innerWidth > 600) {
                    text.style.transition = 'margin-top 0.4s cubic-bezier(.16,.66,0,.99), opacity 0.5s ease';
                } else {
                    text.style.transition = 'margin-top 0.4s cubic-bezier(.16,.66,0,.99)';
                }
                text.style.marginTop = '0px';
                text.style.opacity = 1;
            }, startTime + 700);
        },
        changeSite() {
            const contentContainer = this.$refs.contentContainer;
            contentContainer.style.opacity = '0';

        },
        changeOpacity() {
            const contentContainer = this.$refs.contentContainer;
            contentContainer.style.opacity = '0';
        },
        calculatingHeight() {
            const h1Height = this.$refs.h1.clientHeight;
            const h2Height = this.$refs.h2.clientHeight;
            const h2 = this.$refs.h2;
            const h3 = this.$refs.h3;
            const h3Height = this.$refs.h3.clientHeight;
            const contentContainer = this.$refs.contentContainer;

            let marginTopH2 = h2.style.marginTop;
            let marginTopH3 = h3.style.marginTop;

            let heightTotal = h1Height + h2Height + h3Height + marginTopH3 + marginTopH2

            contentContainer.style.top = (window.innerHeight - heightTotal) / 2 + 'px';
        }
    }
}

</script>
  
<style scoped lang="scss">
@import './style.scss';
</style>