
<template>
  <div>
    <div v-if="!$checkIsMobileDevice()" class="desktop-container" ref="desktopContainer">
      <ProjectSingleView :slides="slides" :projectTitle="projectTitle" :projectDescription="projectDescription"
        :roleCreditList="roleCreditList" :creditsList="creditsList" :projectCTAHref="projectCTAHref"
        :projectCTAText="projectCTAText" :projectName="projectName" :nextProject="nextProject" :nextProjectLinkDesktop="nextProjectLinkDesktop"/>
    </div>

    <div v-else-if="isLoaded" class="mobile-container" ref="mobileContainer">
      <ProjectSingleViewMobile :projectData="projectData" :projectTitle="projectTitle"
        :projectDescription="projectDescription" :roleCreditList="roleCreditList" :creditsList="creditsList"
        :nextProject="nextProject" :nextProjectLink="nextProjectLink" />
    </div>
  </div>
  <LoadingSpinner v-if="isLoadingSpinner" />
</template>

<script>
import { onMounted } from 'vue';
import { useTitle } from '@/scripts/title';
import ProjectSingleView from '@/components/templates/ProjectSingleView/ProjectSingleView.vue';
import ProjectSingleViewMobile from '@/components/templates/ProjectSingleView/ProjectSingleViewMobile/ProjectSingleViewMobile.vue';
import LoadingSpinner from '@/components/templates/LoadingSpinner/LoadingSpinner.vue';

export default {
  name: 'GenericExhibitionPlatformPage',
  components: {
    ProjectSingleView,
    ProjectSingleViewMobile,
    LoadingSpinner
  },
  data() {
    return {
      isLoaded: true,
      isLoadingSpinner: true,
      slides: [
        {
          id: 1,
          image: '/images/p04/p04_img_00.jpg',
        },
        {
          id: 2,
          image: '/images/p04/p04_img_01.jpg',
        },
        {
          id: 3,
          image: '/images/p04/p04_img_02.jpg',
        },
      ],
      projectTitle: 'GENERIC EXHIBITION PLATFORM',
      projectName: 'GENERIC EXHIBITION PLATFORM 20022 - 2023',
      projectDescription: 'The generic exhibition platform features a dynamic virtual exhibition space using a profile-based recommender system. It is accessible on GitHub, encouraging art organizations to create digital exhibitions. Digital 3D assets are to be uploaded to the respective Content Management System together with information on the assets (author, title, description, etc.), and keywords.<br><br> The project was realized in the frame of BEYOND MATTER, an international research project led by the ZKM | Karlsruhe.',
      roleCreditList: [
        'UI & UX Design',
        'Motion Design',
        'Functional Prototyping',
        '3D Modeling',
      ],
      creditsList: [
        '<a href="https://beyondmatter.eu/" target="_blank" style="color: white; position:relative; z-index: 5 !important;">Beyond Matter Project (Client)</a>',
        'Livia Nolasco Rozsas (Concept)',
        '<a <a href="https://zkm.de/en" target="_blank" style="color: white; position:relative; z-index: 5 !important;">ZKM | Karlsruhe (Client)</a>',
        '<a href="https://www.netzbewegung.com/de/start/" target="_blank" style="color: white; position:relative; z-index: 5 !important;">Netzbewegung (Development)</a>',
      ],
      projectCTAHref: 'https://genericexhibitionplatform.beyondmatter.eu/',
      projectCTAText: 'VIEW PROJECT ONLINE',
      nextProjectLinkDesktop: '/projects/iconoclash',

      // Mobile
      nextProjectLink: '/projects/iconoclash',
      nextProject: 'GO TO NEXT PROJECT ICONOCLASH',
      projectData: [
        { image: '/images/mobile/p04/p04_img_00.jpg' },
        { image: '/images/mobile/p04/p04_img_01.jpg' },
        { image: '/images/mobile/p04/p04_img_02.jpg' },
      ],
    };
  },
  mounted() {
    document.dispatchEvent(new Event('render-event'));
    
    if (!this.$checkIsMobileDevice()) {
    this.showContainerDesktop();
  } else {
    this.showContainerMobile();
  }
  },
  setup() {
    const titleState = useTitle();
    onMounted(() => {
      titleState.title = 'Matthias Heckel - Projects | Generic Exhibition Platform';
    });
    return {};
  },
  methods: {
    showContainerDesktop() {
      const desktopContainer = this.$refs.desktopContainer;
      const imagesToLoad = this.slides.map(item => item.image);
      let loadedImages = 0;

      const handleImageLoad = () => {
        loadedImages++;

        if (loadedImages === imagesToLoad.length) {
     
          desktopContainer.style.opacity = '1';
          this.isLoadingSpinner = false;
        }
      };

      imagesToLoad.forEach(src => {
        const img = new Image();
        img.src = src;
        img.onload = handleImageLoad;
      });
    },
    showContainerMobile() {
      const mobileContainer = this.$refs.mobileContainer;
      const imagesToLoad = this.projectData.map(item => item.image);
      let loadedImages = 0;

      const handleImageLoad = () => {
        loadedImages++;

        if (loadedImages === imagesToLoad.length) {

          mobileContainer.style.opacity = '1';
          this.isLoadingSpinner = false;
        }
      };

      imagesToLoad.forEach(src => {
        const img = new Image();
        img.src = src;
        img.onload = handleImageLoad;
      });
    },
  },
}
</script>

<style scoped lang="scss">
@import './style.scss';

@media only screen and (max-width: 799px) {
  ::v-deep #slide-img-1 {
    filter: brightness(0.85);
    width: 1920px;
    height: auto;
  }

  ::v-deep #slide-img-2,
  ::v-deep #slide-img-3 {
    filter: brightness(0.9);
    width: 80vw;
    height: auto;
    border: 8px solid white;
  }
}

@media only screen and (min-width: 800px) and (max-width: 1929px) {

  ::v-deep #slide-img-1 {
    filter: brightness(0.85);
    width: 1920px;
    height: auto;
  }

  ::v-deep #slide-img-2,
  ::v-deep #slide-img-3 {
    filter: brightness(0.9);
    width: 70vw;
    height: auto;
    border: 12px solid white;
  }

}

@media only screen and (min-width: 1920px) {

  ::v-deep #slide-img-1 {
    filter: brightness(0.85);
    width: 100vw;
    height: auto;
  }

  ::v-deep #slide-img-2,
  ::v-deep #slide-img-3 {
    filter: brightness(0.9);
    width: auto;
    height: 60vh;
    border: 12px solid white;
  }

}
</style>
