<template>
        <h1>Privacy Policy</h1>
          <p>Personal data (usually referred to just as „data“ below) will only be processed by us to
            the
            extent necessary and for the purpose of providing a functional and user-friendly
            website,
            including its contents, and the services offered there.</p>
          <p>Per Art. 4 No. 1 of Regulation (EU) 2016/679, i.e. the General Data Protection Regulation
            (hereinafter referred to as the „GDPR“), „processing“ refers to any operation or set of
            operations such as collection, recording, organization, structuring, storage,
            adaptation,
            alteration, retrieval, consultation, use, disclosure by transmission, dissemination, or
            otherwise making available, alignment, or combination, restriction, erasure, or
            destruction
            performed on personal data, whether by automated means or not.</p>
          <p>The following privacy policy is intended to inform you in particular about the type,
            scope,
            purpose, duration, and legal basis for the processing of such data either under our own
            control
            or in conjunction with others. We also inform you below about the third-party components
            we
            use
            to optimize our website and improve the user experience which may result in said third
            parties
            also processing data they collect and control.</p>
          <p>Our privacy policy is structured as follows:</p>
          <p>I. Information about us as controllers of your data<br>II. The rights of users and data
            subjects<br>III. Information about the data processing</p>
          <h2>I. Information about us as controllers of your data</h2>
          <p>The party responsible for this website (the „controller“) for purposes of data protection
            law
            is:
          </p>
          <p> Matthias Heckel<br>Hegaustr. 2a<br>76199 Karlsruhe<br><br>E-Mail: mail@matthiasheckel.com </p>
          <h2>II. The rights of users and data subjects</h2>
          <p>With regard to the data processing to be described in more detail below, users and data
            subjects
            have the right</p>
          <ul>
            <li>to confirmation of whether data concerning them is being processed, information
              about
              the
              data being processed, further information about the nature of the data processing,
              and
              copies of the data (cf. also Art. 15 GDPR);</li>
            <li>to correct or complete incorrect or incomplete data (cf. also Art. 16 GDPR);</li>
            <li>to the immediate deletion of data concerning them (cf. also Art. 17 DSGVO), or,
              alternatively, if further processing is necessary as stipulated in Art. 17 Para. 3
              GDPR,
              to
              restrict said processing per Art. 18 GDPR;</li>
            <li>to receive copies of the data concerning them and/or provided by them and to have
              the
              same
              transmitted to other providers/controllers (cf. also Art. 20 GDPR);</li>
            <li>to file complaints with the supervisory authority if they believe that data
              concerning
              them
              is being processed by the controller in breach of data protection provisions (see
              also
              Art.
              77 GDPR).</li>
          </ul>
          <p>In addition, the controller is obliged to inform all recipients to whom it discloses data
            of
            any
            such corrections, deletions, or restrictions placed on processing the same per Art. 16,
            17
            Para.
            1, 18 GDPR. However, this obligation does not apply if such notification is impossible
            or
            involves a disproportionate effort. Nevertheless, users have a right to information
            about
            these
            recipients.</p>
          <p><strong>Likewise, under Art. 21 GDPR, users and data subjects have the right to object to
              the
              controller’s future processing of their data pursuant to Art. 6 Para. 1 lit. f)
              GDPR. In
              particular, an objection to data processing for the purpose of direct advertising is
              permissible.</strong></p>
          <h2>III. Information about the data processing</h2>
          <p>Your data processed when using our website will be deleted or blocked as soon as the
            purpose
            for
            its storage ceases to apply, provided the deletion of the same is not in breach of any
            statutory
            storage obligations or unless otherwise stipulated below.</p>
          <h3 class="jet-listing-dynamic-field__content">Server data</h3>
          <p>For technical reasons, the following data sent by your internet browser to us or to our
            server
            provider will be collected, especially to ensure a secure and stable website: These
            server
            log
            files record the type and version of your browser, operating system, the website from
            which
            you
            came (referrer URL), the webpages on our site visited, the date and time of your visit,
            as
            well
            as the IP address from which you visited our site.</p>
          <p>The data thus collected will be temporarily stored, but not in association with any other
            of
            your
            data.</p>
          <p>The basis for this storage is Art. 6 Para. 1 lit. f) GDPR. Our legitimate interest lies
            in
            the
            improvement, stability, functionality, and security of our website.</p>
          <p>The data will be deleted within no more than seven days, unless continued storage is
            required
            for
            evidentiary purposes. In which case, all or part of the data will be excluded from
            deletion
            until the investigation of the relevant incident is finally resolved.</p>
          <h3 class="jet-listing-dynamic-field__content">Instagram</h3>
          <p>To advertise our products and services as well as to communicate with interested parties
            or
            customers, we have a presence on the Instagram platform.</p>
          <p>On this social media platform, we are jointly responsible with Meta Platforms Ireland
            Limited, 4
            Grand Canal Square, Dublin 2, Irland.</p>
          <p>The data protection officer of Instagram can be reached via this contact form:</p>
          <p><a href="https://www.facebook.com/help/contact/540977946302970" target="_blank"
              rel="noopener nofollow">https://www.facebook.com/help/contact/540977946302970</a>
          </p>
          <p>We have defined the joint responsibility in an agreement regarding the respective
            obligations
            within the meaning of the GDPR. This agreement, which sets out the reciprocal
            obligations,
            is
            available at the following link:</p>
          <p><a href="https://www.facebook.com/legal/terms/page_controller_addendum" target="_blank"
              rel="noopener nofollow">https://www.facebook.com/legal/terms/page_controller_addendum</a>
          </p>
          <p>The legal basis for the processing of the resulting and subsequently disclosed personal
            data
            is
            Art. 6 para. 1 lit. f GDPR. Our legitimate interest lies in the analysis, communication,
            sales,
            and promotion of our products and services.</p>
          <p>The legal basis may also be your consent per Art. 6 para. 1 lit. a GDPR granted to the
            platform
            operator. Per Art. 7 para. 3 GDPR, you may revoke this consent with the platform
            operator at
            any
            time with future effect.</p>
          <p>When accessing our online presence on the Instagram platform, Facebook Ireland Ltd. as
            the
            operator of the platform in the EU will process your data (e.g. personal information, IP
            address, etc.).</p>
          <p>This data of the user is used for statistical information on the use of our company
            presence
            on
            Instagram. Facebook Ireland Ltd. uses this data for market research and advertising
            purposes
            as
            well as for the creation of user profiles. Based on these profiles, Facebook Ireland
            Ltd.
            can
            provide advertising both within and outside of Instagram based on your interests. If you
            are
            logged into Instagram at the time you access our site, Facebook Ireland Ltd. will also
            link
            this
            data to your user account.</p>
          <p>If you contact us via Instagram, the personal data your provide at that time will be used
            to
            process the request. We will delete this data once we have completely responded to your
            query,
            unless there are legal obligations to retain the data, such as for subsequent
            fulfillment of
            contracts.</p>
          <p>Facebook Ireland Ltd. might also set cookies when processing your data.</p>
          <p>If you do not agree to this processing, you have the option of preventing the
            installation of
            cookies by making the appropriate settings in your browser. Cookies that have already
            been
            saved
            can be deleted at any time. The instructions to do this depend on the browser and system
            being
            used. For Flash cookies, the processing cannot be prevented by the settings in your
            browser,
            but
            instead by making the appropriate settings in your Flash player. If you prevent or
            restrict
            the
            installation of cookies, not all of the functions of Instagram may be fully usable.</p>
          <p>Details on the processing activities, their suppression, and the deletion of the data
            processed
            by Instagram can be found in its privacy policy:</p>
          <p><a href="https://help.instagram.com/519522125107875" target="_blank"
              rel="noopener nofollow">https://help.instagram.com/519522125107875</a></p>
          <p>It cannot be excluded that the processing by Facebook Ireland Ltd. will also take place
            in
            the
            United States by Meta Platforms, Inc., 1601 Willow Road, Menlo Park, California 94025.
          </p>
          <h3 class="jet-listing-dynamic-field__content">LinkedIn</h3>
          <p>We maintain an online presence on LinkedIn to present our company and our services and to
            communicate with customers/prospects. LinkedIn is a service of LinkedIn Ireland
            Unlimited
            Company, Wilton Plaza, Wilton Place, Dublin 2, Irland, a subsidiary of LinkedIn
            Corporation,
            1000 W. Maude Avenue, Sunnyvale, CA 94085, USA.</p>
          <p>We would like to point out that this might cause user data to be processed outside the
            European
            Union, particularly in the United States. This may increase risks for users that, for
            example,
            may make subsequent access to the user data more difficult. We also do not have access
            to
            this
            user data. Access is only available to LinkedIn.</p>
          <p>The LinkedIn privacy policy can be found here:</p>
          <p><a href="https://www.linkedin.com/legal/privacy-policy" target="_blank"
              rel="noopener nofollow">https://www.linkedin.com/legal/privacy-policy</a></p>
          <h3 class="jet-listing-dynamic-field__content">General introduction</h3>
          <p>General linking to third-party profiles</p>
          <p>The provider includes a link on the website to the social media listed below.</p>
          <p>The legal basis for this is Article 6 para. 1 lit. f GDPR. The legitimate interest of the
            provider is to improve the quality of use of the website.</p>
          <p>The plugins are integrated via a linked graphic. The user is only forwarded to the
            service of
            the
            respective social media by clicking on the corresponding graphic.</p>
          <p>After the customer has been forwarded, information about the user is recorded by the
            respective
            social media. This is initially data such as IP address, date, time and page visited. If
            the
            user is logged into his/her user account of the respective social media at the same
            time,
            the
            social media operator can, if required, assign the information collected from the user’s
            specific visit to the user’s personal account. If the user interacts via a “Share”
            button of
            the
            respective social media, this information can be stored in the user’s personal user
            account
            and,
            if required, be published. If the user wants to prevent the collected information from
            being
            directly assigned to his/her user account, the user must log out before clicking on the
            graphic.
            It is also possible to configure the respective user account accordingly.</p>
          <p>The following social media are linked by the provider:</p>
          <h4 class="jet-listing-dynamic-field__content">Instagram</h4>
          <p>Facebook Ireland Limited, 4 Grand Canal Square, Dublin 2, Ireland, a subsidiary of
            Facebook
            Inc.,
            1601 S. California Ave., Palo Alto, CA 94304, USA.</p>
          <p>Privacy Policy: <a href="https://help.instagram.com/519522125107875" target="_blank"
              rel="noopener nofollow">https://help.instagram.com/519522125107875</a></p>
          <h4 class="jet-listing-dynamic-field__content">LinkedIn</h4>
          <p>LinkedIn Ireland Unlimited Company, Wilton Plaza, Wilton Place, Dublin 2, Irland, a
            subsidiary of
            LinkedIn Corporation, 1000 W. Maude Avenue, Sunnyvale, CA 94085 USA.</p>
          <p>Privacy Policy: <a href="https://www.linkedin.com/legal/privacy-policy" target="_blank"
              rel="noopener nofollow">https://www.linkedin.com/legal/privacy-policy</a></p>
          <h3 class="jet-listing-dynamic-field__content">Matomo</h3>

          <p>This website uses the open source web analytics service Matomo. With the help of Matomo,
            we
            are
            able to collect and analyze data about the use of our website by website visitors. This
            allows
            us to find out, among other things, when which page views were made and from which
            region
            they
            come. We also collect various log files (e.g. IP address, referrer, browser used and
            operating
            systems) and can measure whether our website visitors perform certain actions (e.g.
            clicks,
            purchases, etc.).The use of this analysis tool is based on Art. 6 para. 1 lit. f DSGVO.
            The
            website operator has a legitimate interest in analyzing user behavior in order to
            optimize
            both
            its website and its advertising. If a corresponding consent was requested, the
            processing is
            carried out exclusively on the basis of Art. 6 para. 1 lit. a DSGVO and § 25 para.
            1TTDSG,
            insofar as the consent includes the storage of cookies or access to information in the
            user's
            terminal device (e.g. device fingerprinting) within the meaning of the TTDSG. The
            consent
            can be
            revoked at any time.</p>
          <h4>IP anonymization</h4>
          <p>We use IP anonymization for the analysis with Matomo. This means that your IP address is
            shortened before analysis so that it can no longer be clearly assigned to you.</p>
          <h4>Cookieless analysis</h4>
          <p>We have configured Matomo so that it does not store any cookies in your browser.</p>
          <h4>Hosting</h4>
          <p>We host Matomo at the same server as our website is hosted:<br><br>
            STRATO AG<br>
            Otto-Ostrowski-Straße 7<br>
            10249 Berlin
          </p>


          <p><a href="https://www.generator-datenschutzerklärung.de" target="_blank" rel="noopener">Model
              Data
              Protection Statement</a> for <a href="https://www.ratgeberrecht.eu/" target="_blank"
              rel="noopener">Anwaltskanzlei Weiß &amp; Partner</a> with elements of the generated
            privacy
            policy from <a href="https://www.e-recht24.de">e-recht24</a>
          </p>

 </template>
     
 <script>
 export default {
     name: 'PrivacyPolicyEnglish',
     mounted() {
     },
     methods: {
     }
 };
 </script>
     
 <style scoped lang="scss">
@import './style.scss';
 </style>