<template>
  <div class="content-container" ref="contentContainer">
    <div>
      <RotatingCloud 
      v-if="isRotatingCloud" 
      @mousedown="hideContent" 
      @mouseup="showContent" 
      />
    </div>
    <div class="text-container" ref="textContainer">
      <h1>LET'S <br> DISCOVER</h1>
      <h2>New worlds</h2>
      <h3>
        <a href="tel:+491632585066">+49 163 2585066</a>
        <a class="m-container"
          href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;&#109;&#97;&#105;&#108;&#64;&#109;&#97;&#116;&#116;&#104;&#105;&#97;&#115;&#104;&#101;&#99;&#107;&#101;&#108;&#46;&#99;&#111;&#109;"
          target="_blank">&#109;&#97;&#105;&#108;&#64;&#109;&#97;&#116;&#116;&#104;&#105;&#97;&#115;&#104;&#101;&#99;&#107;&#101;&#108;&#46;&#99;&#111;&#109;</a>
        <br><br>
        <a href="https://www.instagram.com/matthias.heckel/" target="_blank">INSTAGRAM</a><br>
        <a href="https://www.linkedin.com/in/matthias-heckel-862a61b1/" target="_blank">LINKEDIN</a><br><br>
        <router-link class="legal" to="/contact/legal">© Heckel 2024 | Legal</router-link>
      </h3>
    </div>
  </div>
  <LoadingSpinner v-if="isLoadingSpinner" />
</template>
  
<script>
import { onMounted } from 'vue';
import { useTitle } from '@/scripts/title';
import RotatingCloud from '@/components/templates/RotatingCloud/RotatingCloud.vue';
import LoadingSpinner from '@/components/templates/LoadingSpinner/LoadingSpinner.vue';

export default {
  data() {
    return {
      isRotatingCloud: true,
      isLoadingSpinner: true
    };
  },
  name: 'ContactPage',
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch('setContactPageLeave', true);
    this.closeContent();
    return new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, 500);
    }).then(() => {
      next();
    });
  },
  components: {
    RotatingCloud,
    LoadingSpinner
  },
  mounted() {
    document.dispatchEvent(new Event('render-event'));
    setTimeout(() => {
      this.callContent();
      this.isLoadingSpinner = false;
    }, 500);
  },
  setup() {
    const titleState = useTitle();
    onMounted(() => {
      titleState.title = 'Matthias Heckel - Contact';
    });
    return {};
  },
  methods: {
    callContent() {
      const textContainer = this.$refs.textContainer;
      textContainer.style.opacity = '1';
    },
    closeContent() {
      const textContainer = this.$refs.textContainer;
      textContainer.style.opacity = '0';
    },
    hideContent() {
      const textContainer = this.$refs.textContainer;
      textContainer.style.opacity = '0';
    },
    showContent() {
      const textContainer = this.$refs.textContainer;
      textContainer.style.opacity = '1';
    },
  }
}
</script>
  
<style scoped lang="scss">
@import './style.scss';
</style>