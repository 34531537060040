<!-- TradeMachines Page -->
<template>
  <div>
    <div v-if="!$checkIsMobileDevice()" class="desktop-container" ref="desktopContainer">
      <ProjectSingleView :slides="slides" :projectTitle="projectTitle" :projectDescription="projectDescription"
        :roleCreditList="roleCreditList" :creditsList="creditsList" :projectCTAHref="projectCTAHref"
        :projectCTAText="projectCTAText" :projectName="projectName" :nextProject="nextProject" :nextProjectLinkDesktop="nextProjectLinkDesktop"/>
    </div>

    <div v-else-if="isLoaded" class="mobile-container" ref="mobileContainer">
      <ProjectSingleViewMobile :projectData="projectData" :projectTitle="projectTitle"
        :projectDescription="projectDescription" :roleCreditList="roleCreditList" :creditsList="creditsList"
        :nextProject="nextProject" :nextProjectLink="nextProjectLink" />
    </div>
  </div>
  <LoadingSpinner v-if="isLoadingSpinner" />
</template>

<script>
import { onMounted } from 'vue';
import { useTitle } from '@/scripts/title';
import ProjectSingleView from '@/components/templates/ProjectSingleView/ProjectSingleView.vue';
import ProjectSingleViewMobile from '@/components/templates/ProjectSingleView/ProjectSingleViewMobile/ProjectSingleViewMobile.vue';
import LoadingSpinner from '@/components/templates/LoadingSpinner/LoadingSpinner.vue';

export default {
  name: 'TradeMachinesPage',
  components: {
    ProjectSingleView,
    ProjectSingleViewMobile,
    LoadingSpinner
  },
  data() {
    return {
      isLoaded: true,
      isLoadingSpinner: true,
      slides: [
        {
          id: 1,
          image: '/images/p03/p03_img_00.jpg',
        },
        {
          id: 2,
          image: '/images/p03/p03_img_01.jpg',
        },
        {
          id: 3,
          image: '/images/p03/p03_img_02.jpg',
        },
        {
          id: 4,
          image: '/images/p03/p03_img_03.jpg',
        },
      ],
      projectTitle: 'TRADEMACHINES',
      projectName: 'TRADEMACHINES 20021 - 2023',
      projectDescription: 'TradeMachines is the global search engine for used machinery – from excavators to production machines. They operate an extensive marketplace to showcase their offerings. Collaborating with TradeMachines, I played a key role in a substantial revision of the web application`s UI/UX design.',
      roleCreditList: [
        'UI & UX Design',
        'Functional Prototyping',
      ],
      creditsList: [
        'Trademachines FI GmbH (Client)',
      ],
      projectCTAHref: '',
      projectCTAText: '',
      nextProjectLinkDesktop: '/projects/genericexhibitionplatform',

      // Mobile
      nextProjectLink: '/projects/genericexhibitionplatform',
      nextProject: 'GO TO NEXT PROJECT GENERIC EXHIBITION PLATFORM',
      projectData: [
        { image: '/images/mobile/p03/p03_img_00.jpg' },
        { image: '/images/mobile/p03/p03_img_01.jpg' },
        { image: '/images/mobile/p03/p03_img_02.jpg' },
        { image: '/images/mobile/p03/p03_img_03.jpg' },
      ],
    };
  },
  mounted() {
    document.dispatchEvent(new Event('render-event'));
    if (!this.$checkIsMobileDevice()) {
    this.showContainerDesktop();
  } else {
    this.showContainerMobile();
  }
  },
  setup() {
    const titleState = useTitle();
    onMounted(() => {
      titleState.title = 'Matthias Heckel - Projects | Trademachines';
    });
    return {};
  },
  methods: {
    showContainerDesktop() {
      const desktopContainer = this.$refs.desktopContainer;
      const imagesToLoad = this.slides.map(item => item.image);
      let loadedImages = 0;

      const handleImageLoad = () => {
        loadedImages++;

        if (loadedImages === imagesToLoad.length) {
          // All images have loaded, now show the container
          desktopContainer.style.opacity = '1';
          this.isLoadingSpinner = false;
        }
      };

      imagesToLoad.forEach(src => {
        const img = new Image();
        img.src = src;
        img.onload = handleImageLoad;
      });
    },
    showContainerMobile() {
      const mobileContainer = this.$refs.mobileContainer;
      const imagesToLoad = this.projectData.map(item => item.image);
      let loadedImages = 0;

      const handleImageLoad = () => {
        loadedImages++;

        if (loadedImages === imagesToLoad.length) {
          // All images have loaded, now show the container
          mobileContainer.style.opacity = '1';
          this.isLoadingSpinner = false;
        }
      };

      imagesToLoad.forEach(src => {
        const img = new Image();
        img.src = src;
        img.onload = handleImageLoad;
      });
    },
  },
}
</script>

<style scoped lang="scss">
@import './style.scss';
/* Extra Small Devices (phones) */
@media only screen and (max-width: 799px) {
  
  ::v-deep #slide-img-1,
  ::v-deep #slide-img-3 {
    filter: brightness(0.85);
    width: 2100px;
    height: auto;
  }

  ::v-deep #slide-img-2,
  ::v-deep #slide-img-4 {
    filter: brightness(0.9);
    width: 80vw;
    height: auto;
    border: 12px solid white;
  }

}

/* Extra Large Devices (large desktops) */
@media only screen and (min-width: 800px) and (max-width: 1929px) {

  ::v-deep #slide-img-1,
  ::v-deep #slide-img-3 {
    filter: brightness(0.85);
    width: 2100px;
    height: auto;
  }

  ::v-deep #slide-img-2,
  ::v-deep #slide-img-4 {
    filter: brightness(0.9);
    width: 70vw;
    height: auto;
    border: 12px solid white;
  }

}

/* Extra Extra Large Devices (very large desktops) */
@media only screen and (min-width: 1920px) {

  ::v-deep #slide-img-1,
  ::v-deep #slide-img-3 {
    filter: brightness(0.85);
    width: 100vw;
    height: auto;
  }

  ::v-deep #slide-img-2,
  ::v-deep #slide-img-4 {
    filter: brightness(0.9);
    width: auto;
    height: 60vh;
    border: 12px solid white;
  }

}
</style>
